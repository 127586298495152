import React from 'react';
import './error-box.scoped.scss';
import { Box } from '@material-ui/core';

interface ErrorBoxProps {
  type?: string;
  text: any;
}

export default function ErrorBox(props: ErrorBoxProps) {
  const { type, text } = props;
  return (
    <Box className="ticket-error-box">
      <Box p={1.5}>
        {type && (
          <div className="small-text-theme ticket-text-error">{type}!</div>
        )}
        <div className="small-text-theme">{text}</div>
      </Box>
    </Box>
  );
}
