import React from "react";
import "./route-box.scoped.scss";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Route } from "../../types/Route";
import { PriceInfo } from "../../types/PriceInfo";
import { formatCurrency } from "../../helpers";

interface RouteBoxProps {
  route: Route;
  active?: boolean;
  disabled?: boolean;
  price: PriceInfo;
}

export default function RouteBox(props: RouteBoxProps) {
  const [t] = useTranslation();
  const { route, active, disabled, price } = props;

  return (
    <Box className={"ticket-route-box" + (active ? " active" : "") + (disabled ? " disabled" : "")}>
      <Box px={1} py={2}>
        {route.routeDescription && <Box className="main-text-theme break-word" mb={1} minHeight="36px">via {route.routeDescription}</Box>}
        <div className="small-text-theme">{route.startZone} - {route.endZone}</div>
        <div className="small-text-theme">{t("Anzahl Zonen")}: {route.zoneCount}</div>
        <div className="small-text-theme">{t("Ticket Preis")}: {formatCurrency(price?.priceCardWithoutCZ)}</div>
      </Box>
    </Box>
  );
}