import React, { useEffect, useRef, useState } from 'react';
import './image-crop.scoped.scss';
import Cropper from 'react-cropper';
import { Box, Button } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { TicketContext } from '../../store';
import { TICKET } from '../../enums/ticket';

interface ImageCropProps {
  image: string | undefined;
  onCrop: any;
  crop: boolean;
  imageCropCanvasData?: any;
  isSaving: boolean;
}

interface ImageCropCanvasData {
  canvasData: any;
  cropBoxData: any;
}

export const ImageCrop = (props: ImageCropProps) => {
  const { image, onCrop, isSaving } = props;
  const { ticket } = React.useContext(TicketContext);
  const [t] = useTranslation();
  const cropperRef = useRef<HTMLImageElement>(null);
  const [cropper, setCropper] = useState<Cropper>();
  const sessionStorageService = new SessionStorageService();

  const getCropData = () => {
    const imageElement: any = cropperRef.current;
    const cropperElement: any = imageElement.cropper;
    if (cropperElement && ticket.ticketId !== TICKET.KLIMA) {
      const croppedCanvas = cropperElement.getCroppedCanvas({
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
        width: 120,
        height: 160,
      });
      const url = croppedCanvas && croppedCanvas.toDataURL();
      onCrop(url);
    }
    if (cropperElement && ticket.ticketId === TICKET.KLIMA) {
      const croppedCanvas = cropperElement.getCroppedCanvas({
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
        width: 300,
      });
      // Round
      const roundedCanvas = getRoundedCanvas(croppedCanvas);
      const url = roundedCanvas && roundedCanvas.toDataURL();
      onCrop(url);
    }
  };

  const saveImageCropCanvasData = (event: Cropper.CropEvent) => {
    const imageElement: any = cropperRef.current;
    const cropperElement: any = imageElement.cropper;
    if (cropperElement) {
      const imageCropCanvasData: ImageCropCanvasData = {
        canvasData: cropperElement.getCanvasData(),
        cropBoxData: cropperElement.getCropBoxData(),
      };
      sessionStorageService.setData('imageCropCanvasData', imageCropCanvasData);
    }
  };

  const getRoundedCanvas = (sourceCanvas: any) => {
    const canvas = document.createElement('canvas');
    const context: any = canvas.getContext('2d');
    const width = sourceCanvas.width;
    const height = sourceCanvas.height;
    canvas.width = width;
    canvas.height = height;
    context.imageSmoothingEnabled = true;
    context.drawImage(sourceCanvas, 0, 0, width, height);
    context.globalCompositeOperation = 'destination-in';
    context.beginPath();
    context.arc(
      width / 2,
      height / 2,
      Math.min(width, height) / 2,
      0,
      2 * Math.PI,
      true
    );
    context.fill();
    return canvas;
  };

  useEffect(() => {
    if (props.crop) {
      getCropData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.crop]);

  useEffect(() => {
    if (
      cropper &&
      props.imageCropCanvasData &&
      props.imageCropCanvasData.canvasData &&
      props.imageCropCanvasData.cropBoxData
    ) {
      setTimeout(() => {
        cropper.setCanvasData(props.imageCropCanvasData.canvasData);
        cropper.setCropBoxData(props.imageCropCanvasData.cropBoxData);
      }, 100);
    }
    if (cropper && props.imageCropCanvasData === undefined) {
      setTimeout(() => {
        reset();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropper, props.imageCropCanvasData]);

  const zoomIn = () => {
    if (cropper) {
      cropper.zoom(0.1);
    }
  };

  const zoomOut = () => {
    if (cropper) {
      cropper.zoom(-0.1);
    }
  };

  const moveRight = () => {
    if (cropper) {
      cropper.move(10, 0);
    }
  };

  const moveLeft = () => {
    if (cropper) {
      cropper.move(-10, 0);
    }
  };

  const moveUp = () => {
    if (cropper) {
      cropper.move(0, -10);
    }
  };

  const moveDown = () => {
    if (cropper) {
      cropper.move(0, 10);
    }
  };

  const rotate = () => {
    if (cropper) {
      cropper.rotate(-90);
    }
  };

  const reset = () => {
    if (cropper) {
      cropper.reset();
    }
  };

  return (
    <>
      <div
        className={
          'ticket-upload ' +
          (ticket.ticketId === TICKET.KLIMA ? 'rounded' : 'rect')
        }
      >
        {image && (
          <Cropper
            src={image}
            style={{ height: '282px', width: '100%' }}
            aspectRatio={ticket.ticketId === TICKET.KLIMA ? 1 : 3 / 4}
            cropBoxResizable={false}
            guides={false}
            background={false}
            crop={saveImageCropCanvasData}
            ref={cropperRef}
            dragMode="crop"
            viewMode={0}
            autoCrop={true}
            checkOrientation={false}
            minCropBoxHeight={282}
            disabled={isSaving}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
        )}
        {!image && (
          <Box
            height={'282px'}
            className="drag-prev"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <p className="small-text-theme">
              {t('Bild hierher ziehen und ablegen')}
            </p>
          </Box>
        )}
      </div>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row !important' }}
        justifyContent="center"
        alignItems="center"
        mt={1}
        flexWrap="wrap"
      >
        <Box display="flex">
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn"
            onClick={rotate}
            disabled={!image || isSaving}
          >
            <Rotate90DegreesCcwIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn"
            onClick={zoomIn}
            disabled={!image || isSaving}
          >
            <ZoomInIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn"
            onClick={zoomOut}
            disabled={!image || isSaving}
          >
            <ZoomOutIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn hide-xs"
            onClick={moveDown}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="arrow-alt-circle-down" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn show-xs"
            onClick={reset}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </Box>
        <Box display="flex">
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn show-xs"
            onClick={moveDown}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="arrow-alt-circle-down" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn"
            onClick={moveRight}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="arrow-alt-circle-right" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn"
            onClick={moveUp}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="arrow-alt-circle-up" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn"
            onClick={moveLeft}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="arrow-alt-circle-left" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="upload-image-icon-btn hide-xs"
            onClick={reset}
            disabled={!image || isSaving}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        </Box>
      </Box>
    </>
  );
};
