import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TicketContext } from "../../store";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tab-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    position: "relative",
    padding: "1px"
  },
}));

export default function TicketTabsComponent() {
  const classes = useStyles();
  const [t] = useTranslation();
  const { activeTab, setActiveTab, isTabDisabled, ticket } = React.useContext(TicketContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any, newValue: number) => {
    activeTab > newValue && setActiveTab(newValue);
  };

  return (
    <>
      <Box className={classes.root} display={{xs: "none", md: "block !important"}}>
        <Tabs value={activeTab} onChange={handleChange} aria-label="ticket shop" variant={"fullWidth"}>
          <Tab label={t("ticketauswahl")} {...a11yProps(0)} className="tab-btn" disableRipple disabled={isTabDisabled}/>
          <Tab label={t("kundendaten")} {...a11yProps(1)} className="tab-btn" disableRipple disabled={isTabDisabled}/>
          <Tab label={t("upload")} {...a11yProps(2)} className="tab-btn" disableRipple disabled={isTabDisabled}/>
          <Tab label={t(ticket.isKlimaTicket ? "Ticketkonfiguration" : "Strecke & Route")} {...a11yProps(3)} className="tab-btn" disableRipple disabled={isTabDisabled}/>
          <Tab label={t("zahlung")} {...a11yProps(4)} className="tab-btn" disableRipple disabled={isTabDisabled}/>
        </Tabs>
      </Box>
      <Box className={classes.root} display={{xs: "block", md: "none !important"}}>
        <Tabs value={activeTab} onChange={handleChange} aria-label="ticket shop">
          <Tab label={activeTab === 0 ? t("ticketauswahl") : ""} {...a11yProps(0)} className="tab-btn-mob" disableRipple disabled={isTabDisabled}/>
          <Tab label={activeTab === 1 ? t("kundendaten") : ""} {...a11yProps(1)} className="tab-btn-mob" disableRipple disabled={isTabDisabled}/>
          <Tab label={activeTab === 2 ? t("upload") : ""} {...a11yProps(2)} className="tab-btn-mob" disableRipple disabled={isTabDisabled}/>
          <Tab label={activeTab === 3 ? t(ticket.isKlimaTicket ? "Ticketkonfiguration" : "Strecke & Route") : ""} {...a11yProps(3)} className="tab-btn-mob" disableRipple disabled={isTabDisabled}/>
          <Tab label={activeTab === 4 ? t("zahlung") : ""} {...a11yProps(4)} className="tab-btn-mob" disableRipple disabled={isTabDisabled}/>
        </Tabs>
      </Box>
    </>
  );
}
