import React, { useCallback } from "react";
import "./files-drop-zone.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { fileTypes } from "../../statics/main";

const FilesDropZone = (props: any) => {
  const [t] = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    props.onDropFiles(acceptedFiles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileTypes,
    multiple: false,
    maxFiles: 1
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      {isDragActive && !props.isSaving ? (
        <div className="dropzone-overlay">
          <p className="small-text-theme">{t("Die Datei zum Hochladen freigeben")}</p>
        </div>
      ) : null}
      {props.children}
    </div>
  );
};

export default FilesDropZone;
