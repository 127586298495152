import axios from "axios";

export const setupInterceptors = () => {
  axios.interceptors.request.use(req => {
    // Do something before request is sent
    return req;
  }, err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    res => res,
    err => {
      throw (err.response && err.response.data) || err;
    }
  )
}