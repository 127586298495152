import axios from "axios";
import { config } from "../../config";
const { dataMillURL } = config;

export class DataMillService {
  private guid = "HaMQ3f2C-vohn-r6xh-oR3c-Tx6BH3Xs6YBA";
  private license = "Cb3JV-kjeKJ-qidGc-2NNop-HcYNR";

  public verifyMobilePhoneNumber = async (phonenumber: string, countrycode: string): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append("phonenumber", phonenumber);
      formData.append("countrycode", countrycode);
      formData.append("guid", this.guid);
      formData.append("license", this.license);
      const response = await axios.post(dataMillURL + "phone/format",
        formData);
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public verifyEmail = async (email: string): Promise<any> => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("guid", this.guid);
      formData.append("license", this.license);
      const response = await axios.post(dataMillURL + "email/domain/check",
        formData);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

