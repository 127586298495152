import React from "react";
import "./payment-box.scoped.scss";
import { Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

interface PaymentBoxProps {
  name: string;
  description: string;
  image?: any;
  iconName?: IconName;
  iconPrefix?: IconPrefix;
  active?: boolean;
  disabled?: boolean;
}

export default function PaymentBox(props: PaymentBoxProps) {
  const { name, description, image, active, iconName, iconPrefix, disabled } = props;
  return (
    <Box className={"ticket-payment-box" + (active ? " active" : "") + (disabled ? " disabled" : "")}>
      <Box p={2} display="flex" alignItems="center">
        <Box
          minWidth="60px"
          maxWidth="60px"
          height="80px"
          mr={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {image && <img src={image} alt={name} style={{ maxWidth: "100%" }} />}
          {iconName && iconPrefix && (
            <FontAwesomeIcon icon={[iconPrefix, iconName]} size="3x" color="#000"/>
          )}
        </Box>
        <div>
          <div className="main-text-theme">{name}</div>
          <div className="small-text-theme">{description}</div>
        </div>
      </Box>
    </Box>
  );
}
