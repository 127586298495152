export interface PaymnetBankInfo {
  bic: string;
  iban: string;
  receiver: string;
}

export const paymentBankInfo: PaymnetBankInfo = {
  bic: "OBKLAT2L",
  iban: "AT541500000721068815",
  receiver: "OOE Verkehrsverbund 4020 Linz"
}