export const stage = process.env.REACT_APP_STAGE;
export const config = {
  // Data.Mill
  dataMillURL: "https://api.datamill.solutions/",
  allowDataMill: true,
  // SOAP
  url: process.env.REACT_APP_SOAP_URL || "https://ofgmws.ooevg.at/test/OOService.asmx",
  namespaceURL: "http://ITPRO.OFGM_Online.OOWebService/",
  username: process.env.WSS_USERNAME,
  password: process.env.WSS_PASSWORD,
  // Payment
  apiUrl: process.env.REACT_APP_PAYMENT_URL || "https://ofgmws.ooevg.at/test/ItproDerpServices/api/ofgm/",
  paymentGatewayURL: process.env.REACT_APP_PAYMENT_GATEWAY_URL || "https://test.oppwa.com/",
  entityId: process.env.REACT_APP_ENTITY_ID || "8ac7a4c973562dcb01735700a07204b7",
  SEPA_entityId: process.env.REACT_APP_SEPA_ENTITY_ID || "8ac7a4c779ee496b0179f07a3c6208c7",
};