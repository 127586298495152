import React, { useEffect, useState } from 'react';
import './ticket-configuration-tab.scoped.scss';
import { Trans, useTranslation } from 'react-i18next';
import { TicketContext } from '../../store';
import { Box, Button, Divider, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { parseString } from 'xml2js';
import moment from 'moment';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { TicketsService } from '../../services/tickets/tickets.service';
import { xml2JsonConfig } from '../../statics/main';
import { Route } from '../../types/Route';
import { PriceInfo } from '../../types/PriceInfo';
import {
  formatCurrency,
  handleSessionExpire,
  isSessionExpired,
  scrollToTabs,
} from '../../helpers';
import KlimaticketBox from '../klimaticket-box/klimaticket-box';
// import InfoIcon from '@material-ui/icons/Info';
// import { InfoDialog } from '../../dialogs/confirm-dialog/info-dialog';
import ErrorBox from '../error-box/error-box';
import { Ticket } from '../../types/Ticket';
import { klimaTicketsData } from '../../statics/klima-tickets';
import { KlimaTicketParking } from '../../statics/klima-ticket-parking';

export const TicketConfigurationTab = () => {
  const [t] = useTranslation();
  const ticketsService = new TicketsService();
  const sessionStorageService = new SessionStorageService();
  const sessionID = sessionStorageService.getData('session');
  const {
    setActiveTab,
    activeTab,
    validFrom,
    setKlimaTicket,
    // setKlimaTicketParking,
    klimaTickets,
    klimaTicket,
    klimaTicketParking,
    setSelectedRoute,
    selectedRoute,
    setSelectedPrice,
    selectedPrice,
    isExistingOrder,
  } = React.useContext(TicketContext);

  // used for loading on submit buttom
  const [possiblePathesLoading, setPossiblePathesLoading] = useState(false);
  const [routeError, setRouteError] = useState('');
  const [saving, setSaving] = useState(false);
  // const [modalInfoOpen, setModalInfoOpen] = useState(false);
  // const [selectedInfoBox, setSelectedInfoBox] = useState({} as any);
  const [ticketPrices, setTicketPrices] = useState<PriceInfo[]>([]);

  // const handleClickModalInfoOpen = (info: { title: string; text: string }) => {
  //   setSelectedInfoBox({
  //     title: info.title,
  //     text: info.text,
  //   });
  //   setModalInfoOpen(true);
  // };

  // const handleParkingChange = (
  //   parking: KlimaTicketParking,
  //   checked: boolean,
  //   index: number
  // ) => {
  //   const result: KlimaTicketParking = { ...parking, active: checked };
  //   const klimaTicketParkingCopy = [...klimaTicketParking];
  //   klimaTicketParkingCopy[index] = result;
  //   setKlimaTicketParking([...klimaTicketParkingCopy]);
  // };

  const handleSelectExtraZone = (ticket: Ticket, price: PriceInfo) => {
    // if use deselects his choice.
    if (klimaTicket.ticketname === ticket.ticketname) {
      setKlimaTicket(klimaTickets[0]);
      setSelectedPrice(ticketPrices[0]);
      sessionStorageService.setData('selectedTicket', klimaTickets[0]);
    } else {
      setKlimaTicket(ticket);
      setSelectedPrice(price);
      sessionStorageService.setData('selectedTicket', ticket);
    }
  };

  const back = () => {
    setActiveTab(activeTab - 1);
    scrollToTabs();
  };

  const next = () => {
    setSaving(false);
    setActiveTab(activeTab + 1);
    scrollToTabs();
  };

  const checkSessionExpire = async () => {
    const sessionID = sessionStorageService.getData('session');
    const error: string = (await ticketsService.getErrorText({ sessionID }))
      .documentElement.textContent;
    handleSessionExpire(error);
  };

  const getPossiblePathes = async () => {
    setPossiblePathesLoading(true);
    const payload: any = {
      validFrom: moment(validFrom).utcOffset(0, true).toDate(),
      sessionID,
      fromStageNr: 40000,
      toStageNr: 40000,
      viaStage: 0,
    };
    ticketsService
      .getPossiblePathes(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parsePossiblePathes(err, res)
        );
      });
  };

  const parsePossiblePathes = (err: Error, res: any) => {
    checkSessionExpire();
    if (res) {
      const pricesInfo: PriceInfo[] =
        res['soap:Envelope']['soap:Body']['types:PriceInfo'] || [];
      const route: Route =
        res['soap:Envelope']['soap:Body']['types:Route'] || [];
      const pathDetails = {
        pricesInfo,
        route: Array.isArray(route) ? route : [route],
      };
      setTicketPrices(pricesInfo);
      setPossiblePathesLoading(false);
      setSelectedRoute(pathDetails.route[0]);
      // if there is no selected ticket, then choose default price.
      if (!selectedPrice?.priceInfoId) {
        setSelectedPrice(pricesInfo[0]);
      }
      // re set ticket and price with the correct ids as they return with id = 0 from continue existing order api
      if (isExistingOrder && klimaTicket && selectedPrice) {
        const selectedPriceFromPrevOrder = pricesInfo.filter(
          // eslint-disable-next-line eqeqeq
          (price) => price.ticketCode == klimaTicket.ticketCode
        )[0];
        selectedPriceFromPrevOrder
          ? setSelectedPrice(selectedPriceFromPrevOrder)
          : setSelectedPrice(pricesInfo[0]);
        const selectedTicketFromPrevOrder = klimaTickets.filter(
          // eslint-disable-next-line eqeqeq
          (ticket: Ticket) => ticket.ticketCode == klimaTicket.ticketCode
        )[0];
        selectedTicketFromPrevOrder
          ? setKlimaTicket(selectedTicketFromPrevOrder)
          : setKlimaTicket(klimaTickets[0]);
      }
    }
  };

  const setCardData = async () => {
    setRouteError('');
    const payload = {
      sessionID,
      routeId: selectedRoute.routeId,
      ticketId: klimaTicket.ticketId,
      priceInfoId: selectedPrice.priceInfoId,
      czFrom: false,
      czTo: false,
      parkdeckWels: klimaTicketParking[0].active ? true : false,
    };
    setSaving(true);
    const res = await ticketsService.setCardData(payload);
    if (res.documentElement.textContent === 'false') {
      const error: string = (await ticketsService.getErrorText({ sessionID }))
        .documentElement.textContent;
      handleSessionExpire(error);
      if (!isSessionExpired(error)) {
        setRouteError(error);
        setSaving(false);
      }
    } else {
      next();
    }
  };

  useEffect(() => {
    getPossiblePathes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ticket-config-tab">
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={4} className="ticket-config-first-section">
          <Box component="section">
            <Box component="h2" mb={3} className="main-title-theme">
              {t('Ihr Ticket')}
            </Box>
            <Box component="p" mb={3} className="small-text-theme">
              <Trans
                i18nKey="klimaticket_age_selection"
                values={{ ticketName: klimaTickets[0].ticketname }}
              >
                <b>{t(klimaTickets[0].ticketname)}</b>
              </Trans>
            </Box>
            <Box mb={3}>
              <KlimaticketBox
                zone={t(klimaTickets[0].ticketname)}
                description={t(klimaTicketsData[0].description)}
                price={
                  ticketPrices.length
                    ? formatCurrency(ticketPrices[0]?.priceCardWithoutCZ)
                    : ''
                }
                active={true}
              />
            </Box>
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Divider variant="middle" />
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="ticket-config-second-section">
          <Box component="section">
            <Box component="h2" mb={3} className="main-title-theme">
              {t('Zusätzliche Optionen')}
            </Box>
            <Box component="p" mb={3} className="small-text-theme">
              {t(
                'Bitte wählen Sie aus, ob Sie zusätzlich eine Kernzone benötigen'
              )}
            </Box>
            {klimaTickets.map((item: Ticket, index: number) => {
              const arr = item.ticketname.split('+');
              const ticketName = arr[1]
                ? `+ ${t('Kernzone') + arr[1]}`
                : arr[0];
              return (
                <Box
                  mb={1.5}
                  key={index}
                  display={index === 0 ? 'none' : 'block'}
                >
                  <KlimaticketBox
                    zone={ticketName}
                    description={
                      <Trans
                        i18nKey={klimaTicketsData[index]?.description}
                        values={{ city: klimaTicketsData[index]?.city }}
                      ></Trans>
                    }
                    price={
                      ticketPrices.length
                        ? `+ ${formatCurrency(
                            ticketPrices[index]?.priceCardWithoutCZ -
                              ticketPrices[0]?.priceCardWithoutCZ
                          )}`
                        : ''
                    }
                    active={klimaTicket?.ticketname === item.ticketname}
                    isSelectable={true}
                    onSelect={() =>
                      handleSelectExtraZone(item, ticketPrices[index])
                    }
                  />
                </Box>
              );
            })}
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Divider variant="middle" />
          </Box>
        </Grid>
        {/* START OF PARKING SECTION */}
        {/* <Grid item xs={12} md className="ticket-config-second-section">
          <Box component="section">
            <Box component="h2" mb={3} className="main-title-theme">
              {t("Zusätzliche Optionen")}
            </Box>
            <Box component="p" mb={3} className="small-text-theme">
              {t("Bitte wählen Sie bei Bedarf zusätzliche Optionen aus")}
            </Box>
            {klimaTicketParking.map(
              (parking: KlimaTicketParking, index: number) => {
                return (
                  <Box
                    key={index}
                    mb={4}
                    display="flex"
                    alignItems="flex-start"
                    left={"-10px"}
                    position="relative"
                  >
                    <Checkbox
                      checked={parking.active}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleParkingChange(
                          parking,
                          event.target.checked,
                          index
                        )
                      }
                      name={parking.name}
                      color="primary"
                      style={{ top: "-7px" }}
                    />
                    <Box ml={{ md: 1 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <div className="main-text-theme">{t(parking.name)}</div>
                        <InfoIcon
                          onClick={handleClickModalInfoOpen.bind(null, {
                            title: t(parking.name),
                            text: t(parking.description),
                          })}
                          className="info-icon"
                        ></InfoIcon>
                      </Box>
                      <div className="small-text-theme text-light">
                        {t(parking.description)}
                      </div>
                      <div className="small-text-theme text-light bold">
                        ({t("Gratis")})
                      </div>
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
          <InfoDialog
            open={modalInfoOpen}
            title={selectedInfoBox?.title}
            text={selectedInfoBox?.text}
            setClose={() => setModalInfoOpen(false)}
          />
          <Box display={{ xs: "block", md: "none" }}>
            <Divider variant="middle" />
          </Box>
        </Grid> */}
        {/* END OF PARKING SECTION */}
        <Grid item xs={12} md={4} className="ticket-config-third-section">
          <Box component="section">
            <Box component="h2" mb={3} className="main-title-theme">
              {t('Bestellübersicht')}
            </Box>
            <Box component="p" mb={5} className="small-text-theme">
              {t('Bitte kontrollieren Sie Ihre Bestellung')}
            </Box>
            {/* Region Ticket */}
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              mb={1}
            >
              <Box className="main-text-theme" flexBasis={'50%'}>
                {klimaTickets[0].ticketname}
              </Box>
              <Box className="main-text-theme">
                {ticketPrices.length
                  ? formatCurrency(ticketPrices[0]?.priceCardWithoutCZ)
                  : ''}
              </Box>
            </Box>
            {/* +Zone ticket */}
            {klimaTicket.ticketname !== klimaTickets[0].ticketname && (
              <Box
                display="flex"
                alignItems="flex-end"
                justifyContent="space-between"
                mb={1}
              >
                <Box className="main-text-theme" flexBasis={'50%'}>
                  {klimaTicket.ticketname.split('+')[1]
                    ? t('Aufpreis Kernzone') +
                      klimaTicket.ticketname.split('+')[1]
                    : t('Aufpreis') + ' ' + klimaTicket.ticketname}
                </Box>
                <Box className="main-text-theme">
                  {ticketPrices.length
                    ? formatCurrency(
                        selectedPrice?.priceCardWithoutCZ -
                          ticketPrices[0]?.priceCardWithoutCZ
                      )
                    : ''}
                </Box>
              </Box>
            )}
            {/* Active Parking */}
            {klimaTicketParking.map(
              (parking: KlimaTicketParking, index: number) => {
                return (
                  <Box
                    key={index}
                    display={parking.active ? 'flex' : 'none'}
                    alignItems="flex-end"
                    justifyContent="space-between"
                    mb={1}
                  >
                    <Box className="main-text-theme" flexBasis={'50%'}>
                      {t(parking.name)}
                    </Box>
                    <Box className="main-text-theme">
                      {formatCurrency(parking.price)}
                    </Box>
                  </Box>
                );
              }
            )}
            <Divider variant="middle" style={{ background: '#333' }} />
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              mb={'130px'}
            >
              <Box className="main-text-theme bold" flexBasis={'50%'}>
                {t('Summe')}
              </Box>
              <Box className="main-text-theme bold">
                {ticketPrices.length && selectedPrice?.priceCardWithoutCZ
                  ? formatCurrency(selectedPrice?.priceCardWithoutCZ)
                  : ''}
              </Box>
            </Box>
            {routeError && (
              <Box mb={2}>
                <ErrorBox type={t('error')} text={t(routeError)} />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection={{ xs: 'column-reverse', md: 'row !important' }}
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Box mr={{ md: 2 }} mb={2}>
                <Button
                  onClick={back}
                  className="btn-text"
                  variant="text"
                  color="secondary"
                  disabled={saving}
                  id="04-10"
                >
                  {t('Schritt zurück')}
                </Button>
              </Box>
              <Box
                mb={2}
                width={{ xs: '100%', md: '150px !important' }}
                whiteSpace="nowrap"
              >
                <Button
                  className="btn-theme btn-submit"
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={setCardData}
                  disabled={possiblePathesLoading || ticketPrices.length === 0}
                  id="04-20"
                >
                  {saving ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    t('Zur Kassa')
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
