import React from "react";
import TicketContent from "../../components/ticket-content/ticket-content.component";
import { TicketProvider } from "../../store";

export default function TicketPage() {
  return (
    <TicketProvider>
      <TicketContent/>
    </TicketProvider>
  );
}
