import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { history } from "react-router-guard";
const query: string = history.location.search;
const localeQuery: string = query.split("sprache=")[1];
const locale: string = localeQuery ?  localeQuery.toLowerCase().substr(0,2) : "de";
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false
    },
    lng: locale || 'de',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;