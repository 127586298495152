import React from "react";
import "./success-box.scoped.scss";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface SuccessBoxProps {
  text: string;
}

export default function SuccessBox(props: SuccessBoxProps) {
  const [t] = useTranslation();
  const { text } = props;
  return (
    <Box className="ticket-success-box">
      <Box p={ 1.5 }>
        <div className="small-text-theme ticket-text-success">{t("Erfolg")}!</div>
        <div className="small-text-theme">{text}</div>
      </Box>
    </Box>
  );
}
