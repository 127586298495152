import { appName } from "../../statics/main";

export class SessionStorageService {
  public getData(key: string) {
    const data = sessionStorage.getItem(`${appName}-${key}`);
    return data ? JSON.parse(data) : '';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public setData(key: string, data: any) {
    sessionStorage.setItem(`${appName}-${key}`, JSON.stringify(data));
  }
  public removeData(key: string) {
    sessionStorage.removeItem(`${appName}-${key}`);
  }
  public clearAll() {
    sessionStorage.clear();
  }
}
