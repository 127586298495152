import React, { ReactNode } from "react";
import "./info-box.scoped.scss";
import { Box } from "@material-ui/core";
import { icons } from "../../statics/icons";

class InfoBoxProps {
  title = "";
  color?: string = "#97BF0D";
  children?: ReactNode;
}

export default function InfoBox(props: InfoBoxProps) {
  const { color, title, children } = props;
  return (
    <Box className={"ticket-info-box " + color}>
      <img
        src={
          color === "secondary"
            ? icons.infoRed
            : color === "blue"
            ? icons.infoBlue
            : icons.infoGreen
        }
        alt=""
        className="ticket-info-box-icon"
      />
      <Box py={{ xs: 3, md: 4 }} px={{ xs: 2, md: 4 }}>
        <Box component="h2" className="main-title-theme capitalize" mb={3}>
          {title}
        </Box>
        <div className="main-text-theme">{children}</div>
      </Box>
    </Box>
  );
}
