import React from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

interface InfoDialogProps {
  open: boolean;
  text: any;
  title: string;
  setClose: any;
}

export const InfoDialog = (props: InfoDialogProps) => {
  const [t] = useTranslation();
  const { text, title } = props;
  const handleClose = () => {
    props.setClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-body">{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Schließen")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
