import { icons } from "./icons";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

export interface PaymentTypeData {
  name: string;
  description: string;
  brand: string;
  paymentType: string;
  value: string;
  image?: string;
  iconName?: IconName;
  iconPrefix?: IconPrefix;
}

export const PaymentTypesData: PaymentTypeData[] = [
  {
    name: "Vorkasse",
    image: icons.vorkassa,
    description: "Vorkasse Description",
    brand: "",
    paymentType: "",
    value: "Überweisung"
  },
  {
    name: "Kreditkarte",
    image: icons.mastercard,
    description: "Kreditkarte Description",
    brand: "VISA MASTER",
    paymentType: "DB",
    value: "Online-Kreditkarte"
  },
  {
    name: "EPS (Online Banking)",
    image: icons.eps,
    description: "EPS Description",
    brand: "EPS",
    paymentType: "PA",
    value: "Online-Überweisung"
  },
  // {
  //   name: "Sepa online",
  //   image: icons.sepa,
  //   // iconName: "chart-pie",
  //   // iconPrefix: "fas",
  //   description: "Sepa Description",
  //   brand: "DIRECTDEBIT_SEPA",
  //   paymentType: "DB",
  //   value: "SEPA-Online"
  // },
  {
    name: "Sepa online",
    image: icons.sepa,
    description: "Sepa Description",
    brand: "DIRECTDEBIT_SEPA",
    paymentType: "DB",
    value: "Bankeinzug"
  },
  {
    name: "Apple Pay",
    iconName: "apple-pay",
    iconPrefix: "fab",
    description: "Apple Pay Description",
    brand: "APPLEPAY",
    paymentType: "DB",
    value: "APPLEPAY"
  }
];

export const paymentTypesToDisplayData = ["Online-Kreditkarte", "Online-Überweisung", "Bankeinzug", "APPLEPAY"];

export const SEPA_months = 10;