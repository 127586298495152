import React from "react";
import "./ticket-card-preview.scoped.scss";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid } from "@material-ui/core";
import { icons } from "../../statics/icons";
import { dateFormat } from "../../statics/main";
import moment from "moment";
import { SessionStorageService } from "../../services/session-storage/session-storage";
import { formatCurrency } from "../../helpers";
import { User } from "../../types/User";
import { Ticket } from "../../types/Ticket";
import { TicketContext } from "../../store";
import { PaymentTypeData, SEPA_months } from "../../statics/payment-types";
interface TicketCardPreviewProps {
  selectedPayment: PaymentTypeData;
}

export const TicketCardPreview = (props: TicketCardPreviewProps) => {
  const [t] = useTranslation();
  const sessionStorageService = new SessionStorageService();
  const { selectedPayment } = props;
  const {
    selectedRoute,
    selectedPrice,
    selectedCoreZone,
    customerImageInfo,
  } = React.useContext(TicketContext);
  const customerImage =
    customerImageInfo.imageStream || sessionStorageService.getData("croppedImage");
  const validFrom: Date = sessionStorageService.getData("ticketForm")
    ?.ticketValidFromDate;
  const ticket: Ticket = sessionStorageService.getData("selectedTicket");
  const isKlimaTicket = !(
    ticket.ticketname.includes("Semesterkarte") ||
    ticket.ticketname.includes("Jahreskarte")
  );
  const userStored: User = sessionStorageService.getData("user");
  const totalPrice =
    selectedPrice.priceCardWithoutCZ +
    (selectedCoreZone.czFrom === "ja" ? selectedPrice.priceCZFrom : 0) +
    (selectedCoreZone.czTo === "ja" ? selectedPrice.priceCZTo : 0);
  const validTo = ticket.isSemesterWorkflow
    ? moment(validFrom).add(5, "month").subtract(1, "day").format(dateFormat)
    : moment(validFrom).add(1, "year").subtract(1, "day").format(dateFormat);
  return (
    <Grid item container spacing={3} alignItems="center">
      {/* Semster and year card preview */}
      {!isKlimaTicket && (
        <Grid item xs={12}>
          <Box position="relative" width="100%" minHeight="170px">
            <img
              src={
                ticket.isSemesterWorkflow
                  ? icons.semesterTicketCard
                  : icons.yearTicketCard
              }
              alt=""
              className="ticket-card-image"
              width="100%"
            />
            <Box
              position="absolute"
              left="16%"
              top="9%"
              display="flex"
              alignItems="center"
            >
              <Box fontSize="14px">
                {moment(validFrom).format(dateFormat)} -{" "}
              </Box>
              <Box fontSize="15px" lineHeight="18px" fontWeight="bold" ml={0.5}>
                {validTo}
              </Box>
            </Box>
            <Box position="absolute" left="17%" top="25%" width="17.5%">
              <img src={customerImage} alt="" width="100%" />
            </Box>
            {selectedRoute.startZone && (
              <Box position="absolute" left="37%" top="25%">
                <Box
                  fontSize="14px"
                  lineHeight="14px"
                  fontWeight="bold"
                  fontStyle="italic"
                >
                  {selectedRoute.startZone}
                  {selectedCoreZone.czFrom === "ja" ? " +K" : ""}
                </Box>
              </Box>
            )}
            {selectedRoute.endZone && (
              <Box position="absolute" left="37%" top="34%">
                <Box
                  fontSize="14px"
                  lineHeight="14px"
                  fontWeight="bold"
                  fontStyle="italic"
                >
                  {selectedRoute.endZone}
                  {selectedCoreZone.czTo === "ja" ? " +K" : ""}
                </Box>
              </Box>
            )}
            {selectedRoute.routeDescription && (
              <Box position="absolute" left="37%" top="50%">
                <Box fontSize="10px" lineHeight="10px">
                  Via {selectedRoute.routeDescription}
                </Box>
              </Box>
            )}
            {selectedRoute.zoneCount && (
              <Box position="absolute" left="37%" top="62%">
                <Box fontSize="10px" lineHeight="10px">
                  {selectedRoute.zoneCount} {t("Kernzonen")}
                </Box>
              </Box>
            )}
            <Box position="absolute" left="37%" top="70%">
              <Box fontSize="10px" lineHeight="10px">
                {formatCurrency(totalPrice)} ({t("inkl")}. 10% UST)
              </Box>
            </Box>
            <Box position="absolute" left="37%" bottom="12%">
              <Box fontSize="10px" lineHeight="10px">
                {userStored.firstname}
              </Box>
            </Box>
            <Box position="absolute" left="37%" bottom="7%">
              <Box fontSize="10px" lineHeight="10px">
                {userStored.lastname}
              </Box>
            </Box>
            <Box position="absolute" right="3%" bottom="4%" width="15%">
              <img src={icons.qrCode} alt="QR CODE" />
            </Box>
          </Box>
        </Grid>
      )}
      {/* Klimaticket card preview */}
      {isKlimaTicket && (
        <Grid item xs={12}>
          <Box position="relative" width="100%" minHeight="170px" color="#fff">
            <img
              src={icons.klimaTicketCard}
              alt=""
              className="ticket-card-image"
              width="100%"
            />
            <Box
              position="absolute"
              right="2%"
              top="5%"
              width="9vw"
              height="9vw"
              maxWidth="130px"
              maxHeight="130px"
              minWidth="65px"
              minHeight="65px"
            >
              <img
                src={customerImage}
                alt=""
                className="klimaticket-user-img"
              />
            </Box>
            {/* <Box position="absolute" left="6%" top="10%">
              <Box
                fontSize={{ xs: "25px", lg: "40px", xl: "50px" }}
                lineHeight={{ xs: "25px", lg: "40px", xl: "50px" }}
                fontWeight="bold"
              >
                {t("Klimaticket OÖ")}
              </Box>
            </Box> */}
            <Box position="absolute" left="6%" top="27.5%">
              <Box
                fontSize={{ xs: "15px", lg: "20px", xl: "25px" }}
                lineHeight={{ xs: "15px", lg: "20px", xl: "25px" }}
              >
                {t(ticket.ticketname.split("KlimaTicket OÖ")[1] || ticket.ticketname)}
              </Box>
            </Box>
            <Box position="absolute" left="6%" bottom="47%" fontWeight="bold">
              <Box
                fontSize={{ xs: "10px", lg: "15px", xl: "20px" }}
                lineHeight={{ xs: "10px", lg: "15px", xl: "20px" }}
              >
                {userStored.firstname}
              </Box>
            </Box>
            <Box position="absolute" left="6%" bottom="40%" fontWeight="bold">
              <Box
                fontSize={{ xs: "10px", lg: "15px", xl: "20px" }}
                lineHeight={{ xs: "10px", lg: "15px", xl: "20px" }}
              >
                {userStored.lastname}
              </Box>
            </Box>
            <Box position="absolute" left="6%" bottom="25%">
              <Box fontSize={{ xs: "10px", lg: "15px", xl: "20px" }}>
                {moment(validFrom).format(dateFormat)} - 
              </Box>
            </Box>
            <Box position="absolute" left="6%" bottom="17%">
              <Box
                fontSize={{ xs: "10px", lg: "15px", xl: "20px" }}
                lineHeight="18px"
                fontWeight="bold"
              >
                {validTo}
              </Box>
            </Box>
            {/* <Box position="absolute" right="5%" bottom="8.5%" width="15%">
              <img src={icons.qrCode} alt="QR CODE" />
            </Box> */}
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box component="p" mb={{ xs: 1, sm: 0 }} className="main-text-theme">
          {ticket.isSemesterWorkflow
            ? t("OÖVV Semesterkarte")
            : !isKlimaTicket
            ? t("OÖVV Jahreskarte")
            : t("Klimaticket OÖ")}
        </Box>
        {!isKlimaTicket && selectedRoute.startZone && selectedRoute.endZone && (
          <Box component="p" mb={{ xs: 1, sm: 0 }} className="main-text-theme">
            {t("Von")} {selectedRoute.startZone} {t("nach")}{" "}
            {selectedRoute.endZone}{" "}
            {selectedRoute.routeDescription ? t("über") : " "}{" "}
            {selectedRoute.routeDescription}
          </Box>
        )}
        {isKlimaTicket && (
          <Box component="p" mb={{ xs: 1, sm: 0 }} className="main-text-theme">
            {t(ticket.ticketname.split("KlimaTicket OÖ")[1] || ticket.ticketname)}
          </Box>
        )}
        <Box component="p" mb={{ xs: 1, sm: 0 }} className="main-text-theme">
          {t("Gültig ab")} {moment(validFrom).format(dateFormat)} {t("bis")}{" "}
          {validTo}
        </Box>
        {((selectedCoreZone.czFrom === "ja" && selectedRoute.startZone) ||
          (selectedCoreZone.czTo === "ja" && selectedRoute.endZone)) && (
          <Box component="p" mb={{ xs: 1, sm: 0 }} className="main-text-theme">
            {t("Mit Kernzone")}{" "}
            {selectedCoreZone.czFrom === "ja" ? selectedRoute.startZone : ""}
            {selectedCoreZone.czFrom === "ja" && selectedCoreZone.czTo === "ja"
              ? " - "
              : " "}
            {selectedCoreZone.czTo === "ja" ? selectedRoute.endZone : ""}
          </Box>
        )}
        <Box component="p" mb={{ xs: 1, sm: 0 }} className="main-text-theme">
          {t("Preis")}: <b>{formatCurrency(totalPrice)}</b>{" "}
          {(selectedPayment.value === "SEPA-Online" || selectedPayment.value === "Bankeinzug") && (
            <>
              <Trans
                i18nKey="sepa_installment_text"
                values={{ SEPA_months: SEPA_months }}
              ></Trans>{" "}
              <b>{formatCurrency(totalPrice / SEPA_months)}</b>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
