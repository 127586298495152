// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_TICKET":
      return {
        ...state,
        ticket: action.payload.ticket,
      };
    case "SET_KLIMA_TICKET":
      return {
        ...state,
        klimaTicket: action.payload.klimaTicket,
      };
    case "SET_KLIMA_TICKET_PARKING":
      return {
        ...state,
        klimaTicketParking: action.payload.klimaTicketParking,
      };
    case "SET_TICKETS":
      return {
        ...state,
        tickets: action.payload.tickets,
      };
    case "SET_KLIMA_TICKETS":
      return {
        ...state,
        klimaTickets: action.payload.klimaTickets,
      };
    case "SET_TICKETS_LOADING":
      return {
        ...state,
        ticketsLoading: action.payload.ticketsLoading,
      };
    case "SET_TICKET_VALID_FROM":
      return {
        ...state,
        validFrom: action.payload.validFrom,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    case "SET_ACTIVE_PAYMENT":
      return {
        ...state,
        activePayment: action.payload.activePayment,
      };
    case "SET_PAYMENT_SUCCESS":
      return {
        ...state,
        paymentSuccess: action.payload.paymentSuccess,
      };
    case "SET_PAYMENT_SUCCESS_DATA":
      return {
        ...state,
        paymentSuccessData: action.payload.paymentSuccessData,
      };
    case "SET_PAYMENT_ERROR_DATA":
      return {
        ...state,
        paymentErrorData: action.payload.paymentErrorData,
      };
    case "SET_UNIVERSITIES":
      return {
        ...state,
        universities: action.payload.universities,
      };
    case "SET_CUSTOMER_IMAGE":
      return {
        ...state,
        customerImageInfo: action.payload.customerImageInfo,
      };
    case "SET_CERT_1":
      return {
        ...state,
        certification1Info: action.payload.certification1Info,
      };
    case "SET_CERT_2":
      return {
        ...state,
        certification2Info: action.payload.certification2Info,
      };
    case "SET_USER_IMAGE":
      return {
        ...state,
        originalUserImg: action.payload.originalUserImg,
      };
    case "SET_PAYMENT_TYPES":
      return {
        ...state,
        paymentTypes: action.payload.paymentTypes,
      };
    case "SET_PAYMENT_TYPES_LOADING":
      return {
        ...state,
        paymentTypesLoaded: action.payload.paymentTypesLoaded,
      };
    case "SET_IS_EXTEND_TICKET":
      return {
        ...state,
        isExtendTicket: action.payload.isExtendTicket,
      };
    case "SET_IS_TAB_DISABLED":
      return {
        ...state,
        isTabDisabled: action.payload.isTabDisabled,
      };
    case "SET_SELECTED_ROUTE":
      return {
        ...state,
        selectedRoute: action.payload.selectedRoute,
      };
    case "SET_SELECTED_PRICE":
      return {
        ...state,
        selectedPrice: action.payload.selectedPrice,
      };
    case "SET_SELECTED_COREZONE":
      return {
        ...state,
        selectedCoreZone: action.payload.selectedCoreZone,
      };
    case "SET_ORDER_NUMBER":
      return {
        ...state,
        orderNumber: action.payload.orderNumber,
      };
    case "SET_SESSION":
      return {
        ...state,
        sessionID: action.payload.sessionID,
      };
    case "SET_IS_EXISTING_ORDER":
      return {
        ...state,
        isExistingOrder: action.payload.isExistingOrder,
      };
    case "SET_ORDER_TOKEN":
      return {
        ...state,
        orderToken: action.payload.orderToken,
      };
    case "SET_DIFF_INVOICE_ADDRESS":
      return {
        ...state,
        differentInvoiceAddress: action.payload.differentInvoiceAddress,
      };
    case "SET_DISABILITY":
      return {
        ...state,
        handicap: action.payload.handicap,
      };
    default:
      return state;
  }
};