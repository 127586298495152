import React, { useEffect, useRef, useState } from 'react';
import './ticket-upload-tab.scoped.scss';
import { useTranslation } from 'react-i18next';
import { TicketContext } from '../../store';
import { Box, Button, Divider, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageCrop } from '../image-crop/image-crop';
import { setTimeout } from 'timers';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { TicketsService } from '../../services/tickets/tickets.service';
import FilesDropZone from '../files-drop-zone/files-drop-zone.component';
import { fileTypes, maxFileSize } from '../../statics/main';
import ErrorBox from '../error-box/error-box';
import { Certification } from '../../types/Certification';
import { CustomerImage } from '../../types/CustomerImage';
import {
  handleSessionExpire,
  isSessionExpired,
  scrollToTabs,
} from '../../helpers';

export const TicketUploadTab = () => {
  const [t] = useTranslation();
  const sessionStorageService = new SessionStorageService();
  const sessionID = sessionStorageService.getData('session');
  const ticketsService = new TicketsService();
  const {
    setActiveTab,
    activeTab,
    ticket,
    setOriginalUserImg,
    setCertification1Info,
    setCertification2Info,
    setCustomerImageInfo,
    customerImageInfo,
    certification1Info,
    certification2Info,
    originalUserImg,
    isExistingOrder,
  } = React.useContext(TicketContext);
  const [imageCropCanvasData, setImageCropCanvasData] = useState<any>(
    sessionStorageService.getData('imageCropCanvasData') || undefined
  );
  const croppedImage = sessionStorageService.getData('croppedImage');
  const [image, setImage] = useState<string | undefined>(originalUserImg);
  const [crop, setCrop] = useState(false);
  const [customerImage, setCustomerImage] = useState<CustomerImage>({
    ...customerImageInfo,
  });
  const [certification1, setCertification1] = useState<Certification>({
    ...certification1Info,
  });
  const [certification2, setCertification2] = useState<Certification>({
    ...certification2Info,
  });
  const [saving, setSaving] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [droppedFiles, setdroppedFiles] = useState([]);
  const inputUserImgRef = useRef(null);
  const inputCertification1Ref = useRef(null);
  const inputCertification2Ref = useRef(null);
  const universityUploadConfirmation = sessionStorageService.getData(
    'universityUploadConfirmation'
  );

  const getFileExtension = (type: string): string => {
    switch (type) {
      case 'image/bmp':
        return 'bmp';
      case 'image/jpeg':
        return 'jpg';
      case 'image/jpg':
        return 'jpg';
      case 'image/png':
        return 'png';
      case 'application/pdf':
        return 'pdf';
      default:
        return '';
    }
  };

  const validateImageType = async (file: File) => {
    const fileExtension = getFileExtension(file.type);
    const newCustomerImage = {
      ...customerImage,
      fileExtension,
      name: file.name,
      error: '',
    };
    if (file.size > maxFileSize) {
      newCustomerImage.error = 'invalid_file_size';
      setImage(undefined);
    } else if (!fileExtension || fileExtension === 'pdf') {
      newCustomerImage.error = 'invalid_image_format';
      setImage(undefined);
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        sessionStorageService.removeData('imageCropCanvasData');
        setImageCropCanvasData(undefined);
        setImage(reader.result as any);
        setOriginalUserImg(reader.result as any);
        setCustomerImageInfo({ ...newCustomerImage });
      };
      reader.readAsDataURL(file);
    }
    setCustomerImage({ ...newCustomerImage });
  };

  const validateCertificateType = async (file: File, uploadType: string) => {
    const extension = getFileExtension(file.type);
    const certificateObj =
      uploadType === 'certification1'
        ? { ...certification1 }
        : { ...certification2 };
    const certificate: Certification = {
      ...certificateObj,
      extension,
      error: '',
      name: file.name,
    };
    if (file.size > maxFileSize) {
      certificate.error = 'invalid_file_size';
      uploadType === 'certification1'
        ? setCertification1(certificate)
        : setCertification2(certificate);
    } else if (!extension) {
      certificate.error = 'invalid_file_format';
      uploadType === 'certification1'
        ? setCertification1(certificate)
        : setCertification2(certificate);
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        const cert = {
          ...certificate,
          certificationContent: reader.result as any,
        };
        if (uploadType === 'certification1') {
          setCertification1({ ...cert });
          setCertification1Info({ ...cert });
        } else {
          setCertification2({ ...cert });
          setCertification2Info({ ...cert });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const resetInputs = () => {
    try {
      if (inputUserImgRef && inputUserImgRef.current) {
        (inputUserImgRef.current as any).value = '';
      }
      if (inputCertification1Ref && inputCertification1Ref.current) {
        (inputCertification1Ref.current as any).value = '';
      }
      if (inputCertification2Ref && inputCertification2Ref.current) {
        (inputCertification2Ref.current as any).value = '';
      }
    } catch {
      return;
    }
  };

  const handleFileUpload = (e: any, uploadType: string) => {
    e.preventDefault();
    let files: File[] = [];
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (uploadType === 'customerImage') {
      validateImageType(files[0]);
    } else {
      validateCertificateType(files[0], uploadType);
    }
    resetInputs();
  };

  const clickUpload = (uploadType: string) => {
    let element: any;
    if (uploadType === 'customerImage') {
      element = inputUserImgRef.current;
    } else if (uploadType === 'certification1') {
      element = inputCertification1Ref.current;
    } else if (uploadType === 'certification2') {
      element = inputCertification2Ref.current;
    }
    element && element.click();
  };

  const getErrorText = async () => {
    const error: string = (await ticketsService.getErrorText({ sessionID }))
      .documentElement.textContent;
    handleSessionExpire(error);
    if (!isSessionExpired(error)) setUploadError(error);
  };

  const upload = async (imageStream: any) => {
    setUploadError('');
    console.log('Uploading Image...');
    setCustomerImage({ ...customerImage, imageStream });
    setCustomerImageInfo({ ...customerImage, imageStream });
    const payload = {
      sessionID,
      imageStream: imageStream.replace(/^[^,]*,/, ''),
      fileExtension: customerImage.fileExtension.toUpperCase(),
    };
    const res = await ticketsService.uploadCustomerImage(payload);
    setSaving(false);
    if (res.documentElement.textContent === 'false') {
      getErrorText();
      console.log(`Upload Image Failed`);
    } else {
      console.log('Upload Image Complete');
      next();
    }
  };

  const uploadExistingImageFromExistingOrder = async () => {
    setUploadError('');
    console.log('Uploading Image...');
    const imageStream = croppedImage;
    const fileExtension = sessionStorageService.getData('fileExtension');
    setCustomerImage({ ...customerImage, imageStream, fileExtension });
    setCustomerImageInfo({ ...customerImage, imageStream, fileExtension });
    const payload = {
      sessionID,
      imageStream: imageStream.replace(/^[^,]*,/, ''),
      fileExtension: 'PNG',
    };
    const res = await ticketsService.uploadCustomerImage(payload);
    setSaving(false);
    if (res.documentElement.textContent === 'false') {
      getErrorText();
      console.log(`Upload Image Failed`);
    } else {
      console.log('Upload Image Complete');
      next();
    }
  };

  const submit = () => {
    let isMissingFile = false;
    // if it's existing order
    if (
      isExistingOrder &&
      !image &&
      !certification1.certificationContent &&
      !certification2.certificationContent
    ) {
      uploadExistingImageFromExistingOrder();
    } else {
      if (!image) {
        setCustomerImage({
          ...customerImage,
          error: 'complete_required_fields',
        });
        isMissingFile = true;
      }
      if (!isExistingOrder && ticket.isSemesterWorkflow && !certification1.certificationContent) {
        setCertification1({
          ...certification1,
          error: 'complete_required_fields',
        });
        isMissingFile = true;
      }
      if (
        !isExistingOrder &&
        ticket.isSemesterWorkflow &&
        universityUploadConfirmation &&
        !certification2.certificationContent
      ) {
        setCertification2({
          ...certification2,
          error: 'complete_required_fields',
        });
        isMissingFile = true;
      }
    }
    if (isMissingFile || saving) return;
    // on crop trigger true, upload() will be called;
    if (image) {
      setCrop(true);
      setTimeout(() => {
        setCrop(false);
      }, 100);
    }
    setSaving(true);
  };

  const back = () => {
    setActiveTab(activeTab - 1);
    scrollToTabs();
  };

  const next = () => {
    setActiveTab(activeTab + 1);
    scrollToTabs();
  };

  useEffect(() => {
    if (!saving && droppedFiles && droppedFiles.length) {
      validateImageType(droppedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedFiles]);

  return (
    <div className="ticket-upload-tab">
      <Grid container direction="row" spacing={4}>
        <Grid
          item
          xs={12}
          md={ticket.isSemesterWorkflow ? 4 : 6}
          className="ticket-upload-first-section"
        >
          <Box component="section">
            <Box component="h2" mb={3} className="main-title-theme">
              {t('Ausweisbild')}
            </Box>
            <Box component="p" mb={3} className="main-text-theme">
              {t('Laden Sie ein aktuelles Foto von Ihnen hoch')}. {t('ACHTUNG')}
              : {t('nur')} BMP, JPG {t('und')} PNG Format {t('möglich')}.{' '}
              {t('Die maximale Größe des Bildes ist')} 5MB.
            </Box>
            <Box component="p" mb={3} className="small-text-theme">
              {t(
                'Um Probleme bei Fahrscheinkontrollen vorzubeugen, wählen Sie bitte ein Foto mit ausreichender Qualität, auf dem Ihr Gesicht gut erkennbar ist.'
              )}
            </Box>
            <Box
              component="p"
              className={
                'main-text-theme ' + (customerImage.error ? 'required' : '')
              }
            >
              {t('Ausweisbild')} *
            </Box>
            <Box component="p" className="small-text-theme" mb={2}>
              (min. 50 KB, max. 5MB)
            </Box>
            <Box width={{ xl: '75%' }}>
              <input
                ref={inputUserImgRef}
                onChange={(event: any) =>
                  handleFileUpload(event, 'customerImage')
                }
                type="file"
                style={{ display: 'none' }}
                accept={fileTypes}
              />
              <Button
                onClick={() => clickUpload('customerImage')}
                className="btn-theme btn-submit"
                variant="outlined"
                color="secondary"
                disabled={saving}
                id="03-10"
              >
                <FontAwesomeIcon icon="upload" />
                {t('DATEI AUSWÄHLEN')}
              </Button>
            </Box>
            {customerImage.name && (
              <Box component="p" className="small-text-theme" mb={1} mt={1}>
                <FontAwesomeIcon
                  icon={
                    customerImage.error.includes('Format')
                      ? 'times'
                      : 'file-image'
                  }
                  size="lg"
                />{' '}
                {customerImage.name}
              </Box>
            )}
            {customerImage.error && (
              <Box mb={1} mt={1}>
                <ErrorBox
                  type={t('error')}
                  text={t(customerImage.error)}
                ></ErrorBox>
              </Box>
            )}
          </Box>
          <Box display={{ xs: 'block', md: 'none' }}>
            <Divider variant="middle" />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={ticket.isSemesterWorkflow ? 4 : 6}
          className={
            'ticket-upload-second-section ' +
            (!ticket.isSemesterWorkflow ? 'no-border' : '')
          }
        >
          <Box component="section" position="relative">
            <FilesDropZone onDropFiles={setdroppedFiles} isSaving={saving}>
              <ImageCrop
                image={image || croppedImage}
                onCrop={upload}
                crop={crop}
                imageCropCanvasData={imageCropCanvasData}
                isSaving={saving}
              />
            </FilesDropZone>
          </Box>
          {ticket.isSemesterWorkflow && (
            <Box display={{ xs: 'block', md: 'none' }}>
              <Divider variant="middle" />
            </Box>
          )}
        </Grid>

        {ticket.isSemesterWorkflow && (
          <Grid item xs={12} md={4} className="ticket-upload-third-section">
            <Box component="section" position="relative">
              <Box component="h2" mb={3} className="main-title-theme">
                {t('Bestätigungen')}
              </Box>
              <Box component="p" mb={5} className="main-text-theme">
                {t('Laden Sie die notwendigen Bestätigungen hoch')}.{' '}
                {t('Die maximale Größe ist')} 5MB.
              </Box>
              <Box
                component="p"
                mb={1}
                className={
                  'main-text-theme ' + (certification1.error ? 'required' : '')
                }
              >
                {t('Meldezettel')} *
              </Box>
              <Box width={{ xl: '75%' }}>
                <input
                  ref={inputCertification1Ref}
                  onChange={(event: any) =>
                    handleFileUpload(event, 'certification1')
                  }
                  type="file"
                  style={{ display: 'none' }}
                  accept={fileTypes + ',application/pdf'}
                />
                <Button
                  onClick={() => clickUpload('certification1')}
                  className="btn-theme btn-submit"
                  variant="outlined"
                  color="secondary"
                  disabled={saving}
                  id="03-20"
                >
                  <FontAwesomeIcon icon="upload" />
                  {t('DATEI AUSWÄHLEN')}
                </Button>
              </Box>
              {certification1.name && (
                <Box component="p" className="small-text-theme" mb={1} mt={1}>
                  <FontAwesomeIcon
                    icon={
                      certification1.extension.includes('pdf')
                        ? 'file-pdf'
                        : certification1.error.includes('Format')
                        ? 'times'
                        : 'file-image'
                    }
                    size="lg"
                  />{' '}
                  {certification1.name}
                </Box>
              )}
              {certification1.error && (
                <Box mb={1} mt={1}>
                  <ErrorBox
                    type={t('error')}
                    text={t(certification1.error)}
                  ></ErrorBox>
                </Box>
              )}
              {universityUploadConfirmation && (
                <Box mt={3}>
                  <Box
                    component="p"
                    mb={1}
                    className={
                      'main-text-theme ' +
                      (certification2.error ? 'required' : '')
                    }
                  >
                    {t('Inskriptionsbestätigung')} *
                  </Box>
                  <Box component="p" mb={1} className="small-text-theme">
                    {t('für das betreffende Semester')}
                  </Box>
                  <Box width={{ xl: '75%' }}>
                    <input
                      ref={inputCertification2Ref}
                      onChange={(event: any) =>
                        handleFileUpload(event, 'certification2')
                      }
                      type="file"
                      style={{ display: 'none' }}
                      accept={fileTypes + ',application/pdf'}
                    />
                    <Button
                      onClick={() => clickUpload('certification2')}
                      className="btn-theme btn-submit"
                      variant="outlined"
                      color="secondary"
                      disabled={saving}
                      id="03-30"
                    >
                      <FontAwesomeIcon icon="upload" />
                      {t('DATEI AUSWÄHLEN')}
                    </Button>
                  </Box>
                  {certification2.name && (
                    <Box
                      component="p"
                      className="small-text-theme"
                      mb={1}
                      mt={1}
                    >
                      <FontAwesomeIcon
                        icon={
                          certification2.extension.includes('pdf')
                            ? 'file-pdf'
                            : certification2.error.includes('Format')
                            ? 'times'
                            : 'file-image'
                        }
                        size="lg"
                      />{' '}
                      {certification2.name}
                    </Box>
                  )}
                  {certification2.error && (
                    <Box mb={1} mt={1}>
                      <ErrorBox
                        type={t('error')}
                        text={t(certification2.error)}
                      ></ErrorBox>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={4} justify="flex-end" alignItems="center">
        <Grid item xs={12} md={ticket.isSemesterWorkflow ? 4 : 6}>
          {uploadError && (
            <Box mt={2}>
              <ErrorBox type={t('error')} text={t(uploadError)} />
            </Box>
          )}
          <Box
            display="flex"
            flexDirection={{ xs: 'column-reverse', md: 'row !important' }}
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <Box mr={{ md: 2 }} mb={2}>
              <Button
                onClick={back}
                className="btn-text"
                variant="text"
                color="secondary"
                disabled={saving}
                id="03-40"
              >
                {t('Schritt zurück')}
              </Button>
            </Box>
            <Box mb={2} width={{ xs: '100%', md: '130px !important' }}>
              <Button
                onClick={submit}
                className="btn-theme btn-submit"
                variant="contained"
                color="primary"
                id="03-50"
                // disabled={
                //   !isExistingOrder &&
                //   (!image ||
                //     (ticket.isSemesterWorkflow &&
                //       !certification1.certificationContent) ||
                //     (ticket.isSemesterWorkflow &&
                //       universityUploadConfirmati on &&
                //       !certification2.certificationContent) ||
                //     Boolean(customerImage.error) ||
                //     Boolean(certification1.error) ||
                //     Boolean(certification2.error))
                // }
              >
                {saving ? <CircularProgress color="inherit" /> : t('Weiter')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
