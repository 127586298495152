export const TicketStatus = [
  {
    name: "Bestellung eingegangen",
    value: 1,
    status: "Eingegangen"
  },
  {
    name: "Abklärung mit Besteller",
    value: 2,
    status: "Eingegangen"
  },
  {
    name: "Karte kann erstellt werden",
    value: 3,
    status: "Eingegangen"
  },
  {
    name: "Warte auf Fortsetzung durch Kunden",
    value: 4,
    status: "Fehlgeschlagen"
  },
  {
    name: "Bestellung abgeschlossen",
    value: 5,
    status: "Abgeschlossen"
  },
  {
    name: "Abgebrochen",
    value: 6,
    status: "Fehlgeschlagen"
  },
  {
    name: "Warte auf Onlinezahlung",
    value: 7,
    status: "Unbezahlt"
  },
  {
    name: "Onlinezahlung abgebrochen",
    value: 8,
    status: "Unbezahlt"
  },
  {
    name: "Karte erstellt",
    value: 20,
    status: "Abgeschlossen"
  },
  {
    name: "Zur Druckerei exportiert",
    value: 21,
    status: "Abgeschlossen"
  },
  {
    name: "Versendet",
    value: 22,
    status: "Abgeschlossen"
  },
  {
    name: "Storniert",
    value: 23,
    status: "Fehlgeschlagen"
  },
]