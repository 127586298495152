import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { createContext, useReducer } from "react";
import { klimaTicketsParkingData, KlimaTicketParking } from "../../statics/klima-ticket-parking";
import { tickets } from "../../statics/tickets";
import { Certification } from "../../types/Certification";
import { CoreZone } from "../../types/CoreZone";
import { CustomerImage } from "../../types/CustomerImage";
import { PriceInfo } from "../../types/PriceInfo";
import { Route } from "../../types/Route";
import { Ticket } from "../../types/Ticket";
import { University } from "../../types/University";
import TicketReducer from "../reducers/ticket.reducer";

const initialState: any = {
  ticket: {},
  klimaTicket: {},
  klimaTicketParking: klimaTicketsParkingData,
  activeTab: 0,
  paymentSuccess: false,
  paymentSuccessData: {},
  paymentErrorData: "",
  sessionID: "",
  ticketsLoading: false,
  isExtendTicket: false,
  isTabDisabled: false,
  activePayment: "",
  validFrom: null,
  tickets: tickets,
  klimaTickets: [] as Ticket[],
  universities: [],
  certification1Info: new Certification(1),
  certification2Info: new Certification(2),
  customerImageInfo: new CustomerImage(),
  originalUserImg: "",
  paymentTypes: [],
  paymentTypesLoaded: false,
  selectedRoute: {},
  selectedPrice: {},
  selectedCoreZone: new CoreZone(),
  orderNumber: "",
  isExistingOrder: false,
  orderToken: "",
  differentInvoiceAddress: false,
  handicap: false
};

export const TicketContext = createContext(initialState);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TicketProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(TicketReducer, initialState);

  function setTicket(ticket: Ticket) {
    dispatch({
      type: "SET_TICKET",
      payload: { ticket },
    });
  }

  function setKlimaTicket(klimaTicket: Ticket) {
    dispatch({
      type: "SET_KLIMA_TICKET",
      payload: { klimaTicket },
    });
  }

  function setKlimaTicketParking(klimaTicketParking: KlimaTicketParking) {
    dispatch({
      type: "SET_KLIMA_TICKET_PARKING",
      payload: { klimaTicketParking },
    });
  }

  function setTickets(tickets: Ticket[]) {
    dispatch({
      type: "SET_TICKETS",
      payload: { tickets },
    });
  }

  function setKlimaTickets(klimaTickets: Ticket[]) {
    dispatch({
      type: "SET_KLIMA_TICKETS",
      payload: { klimaTickets },
    });
  }

  function setTicketsLoading(ticketsLoading: boolean) {
    dispatch({
      type: "SET_TICKETS_LOADING",
      payload: { ticketsLoading },
    });
  }

  function setTicketValidFrom(validFrom: MaterialUiPickersDate) {
    dispatch({
      type: "SET_TICKET_VALID_FROM",
      payload: { validFrom },
    });
  }

  function setActiveTab(activeTab: number) {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: { activeTab },
    });
  }

  function setActivePayment(activePayment: string) {
    dispatch({
      type: "SET_ACTIVE_PAYMENT",
      payload: { activePayment },
    });
  }

  function setPaymentSuccess(paymentSuccess: boolean) {
    dispatch({
      type: "SET_PAYMENT_SUCCESS",
      payload: { paymentSuccess },
    });
  }

  function setPaymentSuccessData(paymentSuccessData: any) {
    dispatch({
      type: "SET_PAYMENT_SUCCESS_DATA",
      payload: { paymentSuccessData },
    });
  }

  function setPaymentErrorData(paymentErrorData: string) {
    dispatch({
      type: "SET_PAYMENT_ERROR_DATA",
      payload: { paymentErrorData },
    });
  }

  function setUniversities(universities: University[]) {
    dispatch({
      type: "SET_UNIVERSITIES",
      payload: { universities },
    });
  }

  function setCustomerImageInfo(customerImageInfo: any) {
    dispatch({
      type: "SET_CUSTOMER_IMAGE",
      payload: { customerImageInfo },
    });
  }

  function setCertification1Info(certification1Info: Certification) {
    dispatch({
      type: "SET_CERT_1",
      payload: { certification1Info },
    });
  }

  function setCertification2Info(certification2Info: Certification) {
    dispatch({
      type: "SET_CERT_2",
      payload: { certification2Info },
    });
  }

  function setOriginalUserImg(originalUserImg: any) {
    dispatch({
      type: "SET_USER_IMAGE",
      payload: { originalUserImg },
    });
  }

  function setPaymentTypes(paymentTypes: any[]) {
    dispatch({
      type: "SET_PAYMENT_TYPES",
      payload: { paymentTypes },
    });
  }

  function setPaymentTypesLoading(paymentTypesLoaded: boolean) {
    dispatch({
      type: "SET_PAYMENT_TYPES_LOADING",
      payload: { paymentTypesLoaded },
    });
  }

  function setIsTicketExtended(isExtendTicket: boolean) {
    dispatch({
      type: "SET_IS_EXTEND_TICKET",
      payload: { isExtendTicket },
    });
  }

  function setIsTabDisabled(isTabDisabled: boolean) {
    dispatch({
      type: "SET_IS_TAB_DISABLED",
      payload: { isTabDisabled },
    });
  }

  function setSelectedRoute(selectedRoute: Route) {
    dispatch({
      type: "SET_SELECTED_ROUTE",
      payload: { selectedRoute },
    });
  }

  function setSelectedPrice(selectedPrice: PriceInfo) {
    dispatch({
      type: "SET_SELECTED_PRICE",
      payload: { selectedPrice },
    });
  }

  function setSelectedCoreZone(selectedCoreZone: CoreZone) {
    dispatch({
      type: "SET_SELECTED_COREZONE",
      payload: { selectedCoreZone },
    });
  }

  function setOrderNumber(orderNumber: string) {
    dispatch({
      type: "SET_ORDER_NUMBER",
      payload: { orderNumber }
    });
  }

  function setSessionID(sessionID: string) {
    dispatch({
      type: "SET_SESSION",
      payload: { sessionID },
    });
  }

  function setIsExistingOrder(isExistingOrder: boolean) {
    dispatch({
      type: "SET_IS_EXISTING_ORDER",
      payload: { isExistingOrder },
    });
  }
  
  function setOrderToken(orderToken: string) {
    dispatch({
      type: "SET_ORDER_TOKEN",
      payload: { orderToken }
    });
  }

  function setDifferentInvoiceAddress(differentInvoiceAddress: boolean) {
    dispatch({
      type: "SET_DIFF_INVOICE_ADDRESS",
      payload: { differentInvoiceAddress }
    });
  }
  
  function setDisability(handicap: boolean) {
    dispatch({
      type: "SET_DISABILITY",
      payload: { handicap }
    });
  }

  return (
    <TicketContext.Provider
      value={{
        ...state,
        setTicket,
        setKlimaTicket,
        setKlimaTicketParking,
        setActiveTab,
        setActivePayment,
        setPaymentSuccess,
        setTickets,
        setKlimaTickets,
        setTicketValidFrom,
        setUniversities,
        setTicketsLoading,
        setCertification1Info,
        setCertification2Info,
        setCustomerImageInfo,
        setOriginalUserImg,
        setPaymentTypes,
        setPaymentTypesLoading,
        setIsTicketExtended,
        setIsTabDisabled,
        setPaymentSuccessData,
        setPaymentErrorData,
        setSelectedRoute,
        setSelectedPrice,
        setSelectedCoreZone,
        setOrderNumber,
        setSessionID,
        setIsExistingOrder,
        setOrderToken,
        setDifferentInvoiceAddress,
        setDisability
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
