import React from "react";
import "./payment-options.scoped.scss";
import { Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function PaymentOptions() {
  const [t] = useTranslation();
  return (
    <Box className="payment-options-box" p={5}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12} style={{ marginBottom: "25px" }}>
          <Box component="h2" className="main-title-theme">
            {t("Zahlungsmöglichkeiten")}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: "25px" }}>
          <Box width={{ md: "80%" }}>
            <Box component="h3" className="main-subtitle-theme">
              {t("Online Kreditkarte")}:
            </Box>
            <Box component="p" className="main-text-theme">
              {t("Online Kreditkarte text")}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: "25px" }}>
          <Box width={{ md: "80%" }}>
            <Box component="h3" className="main-subtitle-theme">
              {t("SEPA Lastschrift / Ratenzahlung")}:
            </Box>
            <Box component="p" className="main-text-theme">
              {t("SEPA Lastschrift / Ratenzahlung text")}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: "25px" }}>
          <Box width={{ md: "80%" }}>
            <Box component="h3" className="main-subtitle-theme">
              {t("Online Überweisung")}:
            </Box>
            <Box component="p" className="main-text-theme">
              {t("Online Überweisung text")}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ marginBottom: "25px" }}>
          <Box width={{ md: "80%" }}>
            <Box component="h3" className="main-subtitle-theme">
              {t("Apple Pay")}:
            </Box>
            <Box component="p" className="main-text-theme">
              {t("Apple Pay text")}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
