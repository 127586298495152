import React from "react";
import "./ticket-tab-content.component.scoped.scss";
import { TicketContext } from "../../store";
import { TicketSelectionTabComponent } from "../ticket-selection-tab/ticket-selection-tab.component";
import { TicketUserTab } from "../ticket-user-tab/ticket-user-tab";
import { TicketUploadTab } from "../ticket-upload-tab/ticket-upload-tab";
import { TicketTrackTab } from "../ticket-track-tab/ticket-track-tab";
import { TicketPaymentTab } from "../ticket-payment-tab/ticket-payment-tab";
import { TicketConfigurationTab } from "../ticket-configuration-tab/ticket-configuration-tab";

export default function TicketTabContentComponent() {
  const { activeTab, ticket } = React.useContext(TicketContext);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return React.useMemo(() => {
    return (
      <div className="w-100">
        <TabPanel value={activeTab} index={0}>
          <TicketSelectionTabComponent />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <TicketUserTab />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <TicketUploadTab />
        </TabPanel>
        <TabPanel value={activeTab} index={3}>
          {ticket.isKlimaTicket ? (
            <TicketConfigurationTab />
          ) : (
            <TicketTrackTab />
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          <TicketPaymentTab />
        </TabPanel>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);
}
