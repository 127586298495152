export type Icons = {
    [key: string]: string;
}

export const icons: Icons = {
    infoGrey: '/images/info-grey.svg',
    infoRed: '/images/info-red.svg',
    infoGreen: '/images/info-green.svg',
    infoBlue: '/images/info-blue.svg',
    link: '/images/link.svg',
    download: '/images/download.svg',

    eps: '/images/eps.png',
    mastercard: '/images/mastercard.png',
    sepa: '/images/sepa.png',
    vorkassa: '/images/vorkassa.svg',

    yearTicketCard: '/images/year-ticket-card.png',
    semesterTicketCard: '/images/semester-ticket-card.png',
    klimaTicketCard: '/images/klima-ticket-card.png',
    ticketTri: '/images/tickets-drei.svg',
    qrCode: '/images/qrcode.png'
}