import { Ticket } from "../types/Ticket";

export const Klimaticket: Ticket = {
  cert1Id: "00000000-0000-0000-0000-000000000000",
  cert1Required: false,
  cert2Id: "00000000-0000-0000-0000-000000000000",
  cert2Required: false,
  cert3Id: "00000000-0000-0000-0000-000000000000",
  cert3Required: false,
  firstOnMonth: false,
  isSemesterWorkflow: false,
  isKlimaTicket: true,
  tarifLevels: "",
  ticketCode: 0,
  ticketId: 3,
  ticketname: "Klimaticket OÖ"
}

export const tickets: Ticket[] = [
  {
    ...Klimaticket
  },
  {
    cert1: "",
    cert1Id: "44c8024c-f79b-e511-80c5-0050568ad98b",
    cert1Required: true,
    cert2: "",
    cert2Id: "30c8024c-f79b-e511-80c5-0050568ad98b",
    cert2Required: false,
    cert3Id: "00000000-0000-0000-0000-000000000000",
    cert3Required: false,
    firstOnMonth: false,
    isSemesterWorkflow: true,
    tarifLevels: "",
    ticketCode: 53,
    ticketId: 1,
    ticketname: "OÖVV Semesterkarte"
  },
  {
    cert1Id: "00000000-0000-0000-0000-000000000000",
    cert1Required: false,
    cert2Id: "00000000-0000-0000-0000-000000000000",
    cert2Required: false,
    cert3Id: "00000000-0000-0000-0000-000000000000",
    cert3Required: false,
    firstOnMonth: true,
    isSemesterWorkflow: false,
    tarifLevels: "",
    ticketCode: 50,
    ticketId: 2,
    ticketname: "OÖVV Jahreskarte"
  }
]