import TicketPage from "../pages/ticket/ticket.page";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    component: TicketPage,
  },
  {
    path: "*",
    redirect: "/",
  },
];
