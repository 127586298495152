import React, { useContext, useEffect, useState } from 'react';
import './ticket-content.component.scoped.scss';
import { TicketContext } from '../../store';
import { TicketsService } from '../../services/tickets/tickets.service';
import TicketTabContentComponent from '../ticket-tab-content/ticket-tab-content.component';
import TicketTabsComponent from '../ticket-tabs/ticket-tabs.component';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentOptions from '../payment-options/payment-options';
import TicketOptions from '../ticket-options/ticket-options';
import TicketLinks from '../ticket-links/ticket-links';
import { TicketPaymentSuccess } from '../ticket-payment-success/ticket-payment-success';
import { SessionStorageService } from '../../services/session-storage/session-storage';
import { xml2JsonConfig } from '../../statics/main';
import { parseString } from 'xml2js';
import { history } from 'react-router-guard';
import { Ticket } from '../../types/Ticket';
import { University } from '../../types/University';
import { Payment } from '../../types/Payment';
import { handleSessionExpire, scrollToTabs } from '../../helpers';
import { PaymentService } from '../../services/payment/payment.service';
import { TicketRenewResponse } from '../../types/TicketRenewResponse';
import moment from 'moment';
import { MapperService } from '../../services/mapper/mapper.service';
import { RouteStages } from '../../types/RouteStages';
import { LangService } from '../../services/lang/lang.service';
import { Klimaticket } from '../../statics/tickets';
import { config } from '../../config';

export default function TicketContent() {
  const ticketsService = new TicketsService();
  const paymentService = new PaymentService();
  const sessionStorageService = new SessionStorageService();
  const mapperService = new MapperService();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showTabContent, setShowTabContent] = useState(false);
  const {
    activeTab,
    setActiveTab,
    paymentSuccess,
    setTickets,
    setTicket,
    tickets,
    setUniversities,
    setTicketsLoading,
    setPaymentTypes,
    setPaymentTypesLoading,
    setPaymentSuccess,
    setPaymentErrorData,
    setIsTabDisabled,
    setOrderNumber,
    setOrderToken,
    setSessionID,
    setSelectedPrice,
    setSelectedRoute,
    setSelectedCoreZone,
    setTicketValidFrom,
    setIsExistingOrder,
    isExistingOrder,
    setKlimaTicket
  } = useContext(TicketContext);

  const langService = new LangService();
  const lang = langService.getCurrentLang();

  const openSession = async () => {
    const res = await ticketsService.openSession();
    setSessionID(res.documentElement.textContent);
    sessionStorageService.setData('session', res.documentElement.textContent);
  };

  const closeSession = async () => {
    const sessionID = sessionStorageService.getData('session');
    await ticketsService.closeSession({ sessionID });
    sessionStorageService.removeData('session');
  };

  const checkSessionExpire = async () => {
    const sessionID = sessionStorageService.getData('session');
    const error: string = (await ticketsService.getErrorText({ sessionID }))
      .documentElement.textContent;
    handleSessionExpire(error);
  };

  const getAvailableTickets = async () => {
    const sessionID = sessionStorageService.getData('session');
    ticketsService
      .getAvailableTickets({ sessionID })
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parseTickets(err, res)
        );
      });
  };

  const parseTickets = (err: Error, res: any) => {
    checkSessionExpire();
    if (res && res['soap:Envelope']['soap:Body']['types:Ticket']) {
      const tickets: Ticket[] =
        res['soap:Envelope']['soap:Body']['types:Ticket'];
      tickets.unshift(Klimaticket);
      setTickets(tickets);
    }
    setTicketsLoading(false);
  };

  const getUniversities = async () => {
    const sessionID = sessionStorageService.getData('session');
    ticketsService
      .getUniversities({ sessionID })
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parseUniversities(err, res)
        );
      });
  };

  const parseUniversities = (err: Error, res: any) => {
    checkSessionExpire();
    if (res) {
      const universities: University[] =
        res['soap:Envelope']['soap:Body']['types:University'] || [];
      setUniversities(universities);
    }
  };

  const getPossiblePaymentTypes = async () => {
    const sessionID = sessionStorageService.getData('session');
    setPaymentTypesLoading(false);
    ticketsService
      .getPossiblePaymentTypes({ sessionID })
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) =>
          parsePaymentTypes(err, res)
        );
      })
      .fail(function (jqXHR: any, textStatus: string, errorThrown: Error) {
        setPaymentTypesLoading(true);
      });
  };

  const parsePaymentTypes = (err: Error, res: any) => {
    checkSessionExpire();
    setPaymentTypesLoading(true);
    if (res) {
      const paymentTypes: Payment[] =
        res['soap:Envelope']['soap:Body']['types:PaymentType'] || [];
      setPaymentTypes(paymentTypes);
    }
  };

  const ticketSelectionTab = async () => {
    if (!isExistingOrder) {
      setTicketsLoading(true);
      await openSession();
    }
    getAvailableTickets();
  };

  const userTab = async () => {
    const sessionID = sessionStorageService.getData('session');
    if (!sessionID) {
      await openSession();
    }
    getUniversities();
  };

  const getOrderToken = async (orderNumber: number) => {
    await openSession();
    const sessionID = sessionStorageService.getData('session');
    const payload: any = {
      orderNumber,
      sessionID,
    };
    const res = await ticketsService.getOrderToken(payload);
    continueExistingOrder(res.documentElement.textContent);
    setOrderToken(res.documentElement.textContent);
  };

  const confirmSepaPayment = async (paymentId: string, orderNumber: number) => {
    await openSession();
    const sessionID = sessionStorageService.getData('session');
    const payload: any = {
      orderNumber,
      sessionID,
    };
    const res = await ticketsService.getOrderToken(payload);
    const orderToken = res.documentElement.textContent;
    await paymentService.paymentStatusSEPA(paymentId, orderToken);
  };

  const continueExistingOrder = async (orderToken: string) => {
    await openSession();
    const sessionID = sessionStorageService.getData('session');
    const payload: any = {
      orderToken,
      sessionID,
    };
    ticketsService
      .continueExistingOrder(payload)
      .done((data: any, textStatus: string, jqXHR: any) => {
        parseString(jqXHR.responseText, xml2JsonConfig, (err, res) => {
          parseTicketData(err, res);
        });
      });
  };

  const parseTicketData = async (err: Error, res: any) => {
    // const sessionID = sessionStorageService.getData('session');
    const ticketDataResponse: TicketRenewResponse =
      res['soap:Envelope']['soap:Body'];
    if (!ticketDataResponse['types:OrderData']) {
      // const error: string = (await ticketsService.getErrorText({ sessionID }))
      //   .documentElement.textContent;
      // setPaymentErrorData(error);
      history.replace(`/?sprache=${lang}`);
      setActiveTab(0);
      setPaymentLoading(false);
      setIsExistingOrder(false);
      setIsTabDisabled(false);
    } else {
      getPossiblePaymentTypes();
      setTicketData(ticketDataResponse);
    }
  };

  const setTicketData = (res: TicketRenewResponse) => {
    // set order number
    const orderData = res['types:OrderData'];
    setOrderNumber(orderData.orderNr);
    // Set Tab 1 Data (Ticket Selection, Valid from Date).
    const cardRestoreData = res['types:CardRestoreData'];
    const savedTicket = res['types:Ticket'];
    // eslint-disable-next-line eqeqeq
    const selectedTicket =
      tickets.filter(
        // eslint-disable-next-line eqeqeq
        (ticket: Ticket) =>
          // eslint-disable-next-line eqeqeq
          ticket.ticketCode ==
          (savedTicket.ticketCode || cardRestoreData.ticketcode)
      )[0] || Klimaticket;
    setTicket(selectedTicket);
    sessionStorageService.setData('selectedTicket', selectedTicket);
    if (selectedTicket.ticketId === 3) {
      setKlimaTicket(savedTicket);
      sessionStorageService.setData('selectedTicket', savedTicket);
    }
    const ticketId = selectedTicket.ticketId;
    const ticketValidFromDate = moment(res['types:CardData'].validFrom);
    sessionStorageService.setData('ticketForm', {
      ticketId,
      ticketValidFromDate,
    });
    setTicketValidFrom(ticketValidFromDate);
    // Set Tab 2 Data (User Data, University)
    const customerData = Array.isArray(res['types:CustomerData'])
      ? res['types:CustomerData'][0]
      : res['types:CustomerData'];
    const university = res['types:University'];
    const user = mapperService.mapUserData(customerData, university);
    sessionStorageService.setData('user', user);
    // Set Tab 3 Data (Images, Files)
    const customerImage =
      'data:image/png;base64,' + res['types:UploadFile'].imageBase64;
    const fileExtension = res['types:UploadFile'].fileExtension;
    sessionStorageService.setData('croppedImage', customerImage);
    sessionStorageService.setData('fileExtension', fileExtension);
    // Set Tab 4 Data (Routes, Price)
    const routeDetails = res['types:RouteDetails'];
    const routeStages: RouteStages = {
      fromStage: {
        stageName: routeDetails.fromStageName,
        stageNr: routeDetails.fromStageNr,
        zoneNr: routeDetails.fromZoneNr,
      },
      toStage: {
        stageName: routeDetails.toStageName,
        stageNr: routeDetails.toStageNr,
        zoneNr: routeDetails.toZoneNr,
      },
      viaStage: {
        stageName: routeDetails.viaStageName,
        stageNr: routeDetails.viaStageNr,
        zoneNr: routeDetails.viaZoneNr,
      },
    };
    sessionStorageService.setData('routeStages', routeStages);
    const selectedRoute = res['types:Route'] || {};
    setSelectedRoute(selectedRoute);
    const selectedPrice = res['types:PriceInfo'];
    setSelectedPrice(selectedPrice);
    const selectedCZ = {
      czFrom: res['types:CardData'].czFrom ? 'ja' : 'nein',
      czTo: res['types:CardData'].czTo ? 'ja' : 'nein',
    };
    setSelectedCoreZone(selectedCZ);
    setPaymentLoading(false);
  };

  const confirmOnlinePayment = async (
    paymentReference: string,
    orderNr: number
  ) => {
    await openSession();
    const sessionID = sessionStorageService.getData('session');
    const payload = {
      sessionID,
      orderNr,
      paymentReference,
    };
    await ticketsService.confirmOnlinepaymentData(payload);
    const error: string = (await ticketsService.getErrorText({ sessionID }))
      .documentElement.textContent;
    console.log(error);
    await ticketsService.closeSession({ sessionID });
  };

  const checkPaymentStatus = async (checkoutID: string, orderNr: string) => {
    const paymentValue: string = sessionStorageService.getData('paymentValue');
    const entityId =
      paymentValue === 'Bankeinzug' || paymentValue === 'SEPA-Online'
        ? config.SEPA_entityId
        : config.entityId;
    const res = await paymentService.paymentStatus(checkoutID, entityId);
    const paymentStatusRes =
      res && res !== 'server error' ? JSON.parse(res) : {};
    // check for successful result code https://payunity.docs.oppwa.com/reference/resultCodes
    // /^(000\.000\.|000\.100\.1|000\.[36])/.test(paymentStatusRes.result?.code)
    if (
      paymentStatusRes.id &&
      paymentStatusRes.result?.code === '000.000.000'
    ) {
      if (paymentValue === 'Bankeinzug' || paymentValue === 'SEPA-Online') {
        await confirmSepaPayment(paymentStatusRes.id, Number(orderNr));
      }
      await confirmOnlinePayment(paymentStatusRes.id, Number(orderNr));
      setPaymentSuccess(true);
      setPaymentLoading(false);
      sessionStorageService.removeData('paymentValue');
    } else {
      // disable going back to any step.
      setIsTabDisabled(true);
      setPaymentErrorData(
        paymentStatusRes.result?.description || 'payment_status_error'
      );
      await getOrderToken(Number(orderNr));
      setIsExistingOrder(true);
      setActiveTab(4);
      scrollToTabs();
    }
  };

  useEffect(() => {
    // handle scroll to top after tab change on mobile
    if (window.screen.width < 768) {
      scrollToTabs();
    }

    // handle redirect after payment
    if (
      history.location.search &&
      history.location.search.includes('resourcePath=') &&
      !isExistingOrder
    ) {
      // close session after payment successfully.
      closeSession();
      setPaymentLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const checkoutID = urlParams.get('id') || '';
      const orderNr = urlParams.get('orderNr') || '';
      setOrderNumber(orderNr);
      checkPaymentStatus(checkoutID, orderNr);
      return;
    }

    // handle payment from order token
    if (
      history.location.search &&
      history.location.search.includes('orderToken=') &&
      !isExistingOrder
    ) {
      setPaymentLoading(true);
      setIsExistingOrder(true);
      // disable going back to any step.
      setIsTabDisabled(true);
      const urlParams = new URLSearchParams(window.location.search);
      const orderToken = urlParams.get('orderToken') || '';
      continueExistingOrder(orderToken);
      setOrderToken(orderToken);
      setActiveTab(4);
      setShowTabContent(true);
      scrollToTabs();
      return;
    }

    // handle ticket status from external link
    if (
      history.location.search &&
      history.location.search.includes('orderNumber=') &&
      history.location.search.includes('dob=')
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const orderNumber = urlParams.get('orderNumber');
      const birthDate = urlParams.get('dob');
      sessionStorageService.setData('orderNumber', orderNumber);
      sessionStorageService.setData('birthDate', birthDate);
    }

    // show tab 1 content if there is no payment redirect or continue an existing order
    setShowTabContent(true);

    switch (activeTab) {
      // ticketauswahl Tab
      case 0:
        ticketSelectionTab();
        break;
      // kundendaten Tab
      case 1:
        if (isExistingOrder) {
          ticketSelectionTab();
        }
        userTab();
        break;
      // upload Tab
      case 2:
        break;
      // Strecke & Route Tab
      case 3:
        break;
      // zahlung Tab
      case 4:
        if (!isExistingOrder) {
          getPossiblePaymentTypes();
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    const appVersionElement = document.getElementById('app-version');
    if (appVersionElement && process.env.REACT_APP_VERSION)
      appVersionElement.innerHTML = `V${process.env.REACT_APP_VERSION}`;
  }, []);

  return (
    <>
      {!paymentSuccess && (
        <>
          <Box
            className="ticket-content"
            mb={{ xs: 6, md: 9 }}
            minHeight={paymentLoading ? '400px' : '255px'}
          >
            <TicketTabsComponent />
            {!paymentLoading && showTabContent && (
              <Box p={{ xs: 3, md: 5 }} className="ticket-tabs-content">
                <TicketTabContentComponent />
              </Box>
            )}
            {paymentLoading && (
              <Box className="payment-loading">
                <CircularProgress color="inherit" />
              </Box>
            )}
          </Box>
          <Box px={{ md: 5 }}>
            <TicketOptions />
          </Box>
          <Box px={{ md: 5 }} mx={{ xs: '-20px', md: '0px !important' }}>
            <PaymentOptions />
          </Box>
          <Box px={{ md: 5 }}>
            <TicketLinks />
          </Box>
        </>
      )}
      {paymentSuccess && (
        <>
          <Box className="ticket-content" mb={{ xs: 6, md: 9 }}>
            <Box p={{ xs: 3, md: 5 }} className="ticket-tabs-content">
              <TicketPaymentSuccess />
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
