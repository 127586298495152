import React, { useState } from "react";
import "./klimaticket-box.scoped.scss";
import { Box, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { InfoDialog } from "../../dialogs/confirm-dialog/info-dialog";
import { useTranslation } from "react-i18next";

interface KlimaticketBoxProps {
  zone: string;
  description: any;
  price: string;
  active?: boolean;
  isSelectable?: boolean;
  onSelect?: any;
}

export default function KlimaticketBox(props: KlimaticketBoxProps) {
  const { zone, description, price, isSelectable, active, onSelect } = props;
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const [t] = useTranslation();

  const handleClickModalInfoOpen = () => {
    setModalInfoOpen(true);
  };

  const handleClickSelect = () => {
    onSelect();
  };

  return (
    <Box className={"klimaticket-box" + (active ? " active" : "")} p={2}>
      <Box className="main-text-theme" mb={1} fontWeight={500}>
        {zone}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box className="small-text-theme text-light" flexBasis={"75%"}>
          {description}
        </Box>
        <Box className="main-text-theme bold" flexShrink={0}>
          {price}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {isSelectable && !active && (
          <Button
            variant="contained"
            className="small-text-theme select-text"
            onClick={handleClickSelect}
          >
            {t("Auswählen")}
          </Button>
        )}
        {isSelectable && active && (
          <Button
            variant="contained"
            className="small-text-theme select-text"
            onClick={handleClickSelect}
          >
            {t("Abwählen")}
          </Button>
        )}
        <InfoIcon
          onClick={handleClickModalInfoOpen}
          className="info-icon"
        ></InfoIcon>
      </Box>
      <InfoDialog
        open={modalInfoOpen}
        title={zone}
        text={description}
        setClose={() => setModalInfoOpen(false)}
      />
    </Box>
  );
}
