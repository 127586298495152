import React from "react";
import { RouterGuard } from "react-router-guard";
import router from "./router";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUpload,
  faTicketAlt,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faTimes,
  faPrint,
  faFileImage,
  faFilePdf,
  faChartPie
} from "@fortawesome/free-solid-svg-icons";
import {
  faApplePay
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faUpload,
  faTicketAlt,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faTimes,
  faPrint,
  faFileImage,
  faFilePdf,
  faChartPie,
  faApplePay
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const values: any = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 979,
  xl: 1200,
};

function App() {
  const theme = createMuiTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      up: (key: number | "xs" | "sm" | "md" | "lg" | "xl") => `@media (min-width:${values[key]}px)`,
    },
    palette: {
      primary: {
        main: "#000",
        contrastText: "#fff",
      },
      secondary: {
        main: "#333",
        contrastText: "#d3d3d3",
      },
      error: {
        main: "#d64d4b",
      },
      divider: "#e5e5e5",
    },
    spacing: 8,
    typography: {
      fontFamily: "Roboto",
    },
    overrides: {
      MuiInput: {
        input: {
          border: "0 !important",
          font: "inherit !important",
          color: "currentColor !important",
          width: "100% !important",
          height: "1.1876em !important",
          margin: "0 !important",
          display: "block !important",
          padding: "6px 0 7px !important",
          background: "none !important",
          boxShadow: "none !important",
          transition: "none !important",
          borderRadius: "unset !important",
          verticalAlign: "unset !important",
          "&::placeholder": {
            color: "#333333",
            opacity: 1,
          },
          '&:-webkit-autofill': {
            transition: 'color 9999s ease-out, background-color 9999s ease-out !important',
          },
        },
        root: {
          color: "#333333",
        },
      },
      MuiFormLabel: {
        root: {
          marginBottom: "0 !important",
        },
      },
      MuiButton: {
        root: {
          textTransform: "none", // removes uppercase transformation
        },
        textSecondary: {
          color: "#b5b5b5",
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        containedPrimary: {
          color: '#fff !important',
          "&:hover": {
            backgroundColor: "#333",
          },
          "&:disabled": {
            backgroundColor: "#b5b5b5 !important",
          },
        }
      },
    },
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <div className="ticket-shop-main-wrapper">
          <RouterGuard config={router} />
        </div>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
