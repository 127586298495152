import { config } from "../../config";
const { url, namespaceURL, username, password } = config;
declare const $: any;
export class TicketsService {

  private soapConfig: any = {
    url,
    appendMethodToURL: false,
    namespaceURL,
    namespaceQualifier: "tns",
    envAttributes: {
      'xmlns:tns': namespaceURL,
      'xmlns:types': `${namespaceURL}encodedTypes`
    },
    noPrefix: true,
    // MS
    timeout: 1000000
  }

  constructor() {
    if (username && password) {
      this.soapConfig.wss = {
        username,
        password
      }
    }
  }

  public initSoap = () => {
    $.soap(this.soapConfig)
  }

  public callSOAP = (data = {}, method = "") => {
    try {
      this.initSoap();
      return $.soap({
        method,
        SOAPAction: `${namespaceURL + method}`,
        data,
        elementName: `tns:${method}`,
      });
    } catch (err) {
      return err;
    }
  }

  public openSession = async () => this.callSOAP({}, "OpenSession");

  public closeSession = (data: any) => this.callSOAP(data, "CloseSession");

  public getAvailableTickets = (data: any) => this.callSOAP(data, "GetAvailableTickets");

  public getOnlineOrderState = (data: any) => this.callSOAP(data, "GetOnlineOrderState");

  public getErrorText = async (data: any) => this.callSOAP(data, "GetErrorText");

  public setCustomerData = async (data: any) => this.callSOAP(data, "SetCustomerData");

  public setStudentData = async (data: any) => this.callSOAP(data, "SetStudentData");

  public getUniversities = (data: any) => this.callSOAP(data, "GetUniversities");

  public checkInscription = (data: any) => this.callSOAP(data, "CheckInscription");

  public uploadCustomerImage = (data: any) => this.callSOAP(data, "UploadCustomerImage");

  public uploadCertification = (data: any) => this.callSOAP(data, "UploadCertification");

  public getStages = (data: any) => this.callSOAP(data, "GetStages");

  public getPossiblePathes = (data: any) => this.callSOAP(data, "GetPossiblePathes");

  public setCardData = (data: any) => this.callSOAP(data, "SetCardData");

  public getPossiblePaymentTypes = (data: any) => this.callSOAP(data, "GetPossiblePaymentTypes");

  public newOrderWithStoredData = (data: any) => this.callSOAP(data, "NewOrderWithStoredData");

  public confirmOnlinepaymentData = (data: any) => this.callSOAP(data, "ConfirmOnlinepaymentData");

  public cancelOnlinepayment = (data: any) => this.callSOAP(data, "CancelOnlinepayment");

  public continueExistingOrder = (data: any) => this.callSOAP(data, "ContinueExistingOrder");

  public getOrderToken = (data: any) => this.callSOAP(data, "GetOrderToken");

  public continueOrder = (data: any) => this.callSOAP(data, "ContinueOrder");

  public saveOrder = (data: any) => this.callSOAP(data, "SaveOrder");

  public setPaymentData = (data: any) => this.callSOAP(data, "SetPaymentData");

  public getClimateTickets = (data: any) => this.callSOAP(data, "GetClimateTickets");
}
