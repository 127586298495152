export interface KlimaTicketParking {
  name: string,
  description: string,
  active: boolean;
  price: number;
}

export const klimaTicketsParkingData: KlimaTicketParking[] = [
  {
    name: "P+R Berechtigung Wels",
    description: "Kostenlos parken in der Park & Ride Anlage am Hauptbahnhof Wels",
    active: false,
    price: 0
  },
  {
    name: "P+R Berechtigung St. Valentin",
    description: "Kostenlos parken in der Park & Ride Anlage St Valentin",
    active: false,
    price: 0
  }
]