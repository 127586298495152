import React from "react";
import "./ticket-options.scoped.scss";
import { Box, Grid } from "@material-ui/core";
import InfoBox from "../info-box/info-box";
import { useTranslation } from "react-i18next";

export default function TicketOptions() {
  const [t] = useTranslation();
  return (
    <Box className="ticket-options-box" mb={5}>
      <Grid container direction="row" justify="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <Box height="100%" minHeight={{ md: 330, xs: 296 }}>
            <InfoBox title={t("Klimaticket OÖ")} color="blue">
              <Box mb={2}>
                {t(
                  "Das KlimaTicket OÖ revolutioniert die Tarife im Öffentlichen Verkehr in Oberösterreich"
                )}
                .
              </Box>
              <Box mb={2}>
                {t(
                  "Ab 26 Oktober können Sie mit dem neuen KlimaTicket OÖ je nach der von Ihnen gewählten Kategorie entweder in dem von Ihnen bevorzugten regionalen Teilbereich oder sogar im gesamten Verbundgebiet OÖ das gesamte Netz des Öffentlichen Verkehrs nutzen und in jeden Zug, jeden Bus und jede Straßenbahn einsteigen – und das mit nur einem Ticket"
                )}
                .
              </Box>
            </InfoBox>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box height="100%" minHeight={{ md: 330, xs: 296 }}>
            <InfoBox title={t("OÖVV Jahreskarte")} color="secondary">
              <Box mb={2}>
                {t(
                  "Die OÖVV Jahreskarte gilt ein Jahr lang für beliebig viele Fahrten auf der gewählten Strecke Sie ist nicht übertragbar"
                )}
                .
              </Box>
              <Box mb={2}>
                {t(
                  "Gültig zum Beispiel von 1 Mai des Jahres bis 30 April des Folgejahres Gültig immer ab dem Monatsersten"
                )}
                .
              </Box>
              <Box mb={2}>
                {t(
                  "Die OÖVV Jahreskarte kann bis zum 10 des Vormonats bestellt werden"
                )}
                .
              </Box>
            </InfoBox>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box height="100%" minHeight={{ md: 330, xs: 296 }}>
            <InfoBox title={t("OÖVV Semesterkarte")} color="primary">
              <Box mb={2}>
                {t(
                  "Die OÖVV Semesterkarte berechtigt zu Fahrten zwischen Wohn und Studienort in Oberösterreich für 5 Monate Sie ist nicht übertragbar"
                )}
                .
              </Box>
              <Box mb={2}>{t("Voraussetzungen")}:</Box>
              <Box mb={0.5}>
                —{" "}
                <Box component="span" ml={1}>
                  {t("ordentliches Studium an einer berechtigten Einrichtung")}
                </Box>
              </Box>
              <Box mb={0.5}>
                —{" "}
                <Box component="span" ml={1}>
                  {t("Alter zu Semesterbeginn 26")}
                </Box>
              </Box>
              <Box mb={0.5}>
                —{" "}
                <Box component="span" ml={1}>
                  {t("Wohn- und Studienort Oberösterreich")}
                </Box>
              </Box>
            </InfoBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
