interface klimaTicket {
  name: string,
  description: string,
  city?: string
}

export const klimaTicketsData: klimaTicket[] = [
  {
    name: "Region",
    description: "Alle Verkehrsmittel innerhalb des Verbundgebiets OÖ ausgenommen des Stadtverkehrs in Linz, Wels und Steyr"
  },
  {
    name: "+ Kernzone Wels",
    description: "Alle Verkehrsmittel innerhalb des Verbundgebiets OÖ inklusive Stadtverkehr",
    city: "Wels"
  },
  {
    name: "+ Kernzone Steyr",
    description: "Alle Verkehrsmittel innerhalb des Verbundgebiets OÖ inklusive Stadtverkehr",
    city: "Steyr"
  },
  {
    name: "+ Kernzone Linz",
    description: "Alle Verkehrsmittel innerhalb des Verbundgebiets OÖ inklusive Stadtverkehr",
    city: "Linz"
  },
  {
    name: "+ Premium",
    description: "Alle Verkehrsmittel innerhalb des Verbundgebiets OÖ inklusive Stadtverkehr in Linz, Wels und Steyr"
  }
]