import moment from 'moment';
import { CustomerData } from '../../types/CustomerData';
import { University } from '../../types/University';
import { User } from '../../types/User';
export class MapperService {
  public mapUserData = (customerData: CustomerData, university: University) => {
    const {
      sexcode,
      salutation,
      firstname,
      lastname,
      city,
      customerNr,
      matrikelNr,
      ooevgNewsletter,
      dataTransferAccompanyingResearch,
      invoiceSexcode,
      invoiceSalutation,
      invoiceFirstname,
      invoiceLastname,
      invoicePostalCode,
      invoiceCity,
      invoiceAddress,
      invoiceCompanyName,
      invoiceUid,
    } = customerData;

    const user: User = {
      sexcode,
      salutation: salutation || '',
      firstname,
      lastname,
      address: customerData.street,
      postalCode: customerData.plz,
      city,
      birthDate: customerData.birthDate ? moment(customerData.birthDate) : null,
      mail: customerData.email,
      mobilephonenr: customerData.mobilephonenr
        ? String(customerData.mobilephonenr)
        : customerData.phonenr
        ? String(customerData.phonenr)
        : '',
      customerNr,
      ooevgNewsletter,
      dataTransferAccompanyingResearch,
      matrikelNr: matrikelNr || '',
      universityId: university?.id || '',
      invoiceSexcode: invoiceSexcode || sexcode,
      invoiceSalutation: invoiceSalutation || salutation || '',
      invoiceFirstname: invoiceFirstname || firstname,
      invoiceLastname: invoiceLastname || lastname,
      invoicePostalCode,
      invoiceCity,
      invoiceAddress,
      invoiceCompanyName,
      invoiceUid: invoiceUid || '',
    };
    return user;
  };
}
