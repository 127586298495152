import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import 'moment/locale/de';
import { history } from "react-router-guard";
import { LangService } from "../services/lang/lang.service";
import { SessionStorageService } from "../services/session-storage/session-storage";
declare const $: any;

const langService: LangService = new LangService();
const sessionStorageService: SessionStorageService = new SessionStorageService();

export const addLeadingZero = (value = 0) => {
  return value.toString().length === 1 ? `0${value}` : value;
}

export const formatDate = (date: any, format: string) => {
  if (date && format) {
    const momentDate = moment(new Date(date));
    const lang = langService.getCurrentLang();
    moment.locale(lang);
    const formatedDate = momentDate.format(format);
    return formatedDate;
  }
}

export const deleteEmptyProp = (obj: any = {}) => {
  const object = { ...obj };
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      const element = object[key];
      if (!element) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export const limit = (text: string, length: number) => text?.substr(0, length);

export const turncate = (text = "", length: number) => {
  if (text.length > length) {
    return text.substr(0, length) + '...';
  } else {
    return text.substr(0, length);
  }
}

// deep clone (array of objects - array of arrays)
export const deepClone = (arr: any[] = []): any[] => arr.map(item => Array.isArray(item) ? deepClone(item) : (typeof item === "object") ? { ...item } : item);

export const isAllTextSpaces = (text = "") => {
  const regex = /^\s+$/;
  // check if user write spaces at first
  if (regex.test(text)) {
    return true;
  }
  return false;
}

export const isStringHasWhiteSpace = (text = "") => {
  const regex = /\s/;
  // check if user write spaces at first
  if (regex.test(text)) {
    return true;
  }
  return false;
}

export const calculateAge = (birthDate: MaterialUiPickersDate, ValidFrom: MaterialUiPickersDate) => moment(ValidFrom).diff(moment(birthDate), "years");

export const formatCurrency = (price: number) => {
  const formatter = new Intl.NumberFormat("de-AT", {
    style: "currency",
    currency: "EUR"
  });
  return formatter.format(price / 100);
}

export const isSessionExpired = (error: string): boolean => {
  return error === "Keine Session vorhanden oder Session abgelaufen";
}

export const handleSessionExpire = (error: string) => {
  if (isSessionExpired(error)) {
    const lang = langService.getCurrentLang();
    history.push(`/?sprache=${lang}`);
    sessionStorageService.clearAll();
    window.location.reload();
  }
}

export const scrollToTabs = () => {
  $([document.documentElement, document.body]).animate(
    {
      scrollTop: $(".ticket-shop-main-wrapper").offset().top - 100,
    },
    1000
  );
}

export const isIosDevice = () => [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod'
].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);