import React, { useEffect } from "react";
import "./ticket-payment-success.scoped.scss";
import { useTranslation } from "react-i18next";
import { TicketContext } from "../../store";
import { Box, Button, Grid } from "@material-ui/core";
import { icons } from "../../statics/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { history } from "react-router-guard";
import { SessionStorageService } from "../../services/session-storage/session-storage";
import { formatCurrency, scrollToTabs } from "../../helpers";
import { paymentBankInfo } from "../../statics/payment-bank-info";
import { LangService } from "../../services/lang/lang.service";

export const TicketPaymentSuccess = () => {
  const [t] = useTranslation();
  const sessionStorageService = new SessionStorageService();
  const {
    paymentSuccessData,
    orderNumber,
    selectedPrice,
    selectedCoreZone,
    ticket,
  } = React.useContext(TicketContext);
  const [amount, setAmount] = React.useState(0);
  const birthDate = sessionStorageService.getData("user")
    ? sessionStorageService.getData("user").birthDate
    : null;
  const langService = new LangService();
  const lang = langService.getCurrentLang();
  const isKlimaTicket = !(
    ticket?.ticketname?.includes("Semesterkarte") ||
    ticket?.ticketname?.includes("Jahreskarte")
  );
  const ticketStatus = () => {
    sessionStorageService.clearAll();
    sessionStorageService.setData("orderNumber", orderNumber);
    sessionStorageService.setData("birthDate", birthDate);
    history.push(`/?sprache=${lang}`);
    window.location.reload();
  };

  const ticketHome = () => {
    sessionStorageService.clearAll();
    history.push(`/?sprache=${lang}`);
    window.location.reload();
  };

  const print = () => {
    window.print();
    return;
  };

  useEffect(() => {
    scrollToTabs();
    if (paymentSuccessData?.vorkasse && selectedPrice && selectedCoreZone) {
      const amount =
        selectedPrice.priceCardWithoutCZ +
        (selectedCoreZone.czFrom === "ja" ? selectedPrice.priceCZFrom : 0) +
        (selectedCoreZone.czTo === "ja" ? selectedPrice.priceCZTo : 0);
      setAmount(amount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="ticket-payment-success" position="relative" width="100%">
      <Grid container spacing={8}>
        <Grid item xs={12} md={paymentSuccessData?.vorkasse ? 7 : 12}>
          <Box component="section" className="first-section">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box
                  display="flex"
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                  mb={4}
                  width={{ xs: "50%", sm: "80% !important" }}
                  margin={"auto"}
                >
                  <img src={icons.ticketTri} alt="" width="100%" />
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <Box component="h2" mb={3} className="main-title-theme">
                  {t("Vielen Dank")}!
                </Box>
                <Box component="p" mb={1} className="main-text-theme">
                  {t("Vielen Dank für Ihre Bestellung")}.
                </Box>
                <Box component="p" mb={1} className="main-text-theme">
                  {t("Weitere Informationen erhalten Sie in Kürze per E-Mail")}.
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  mt={2}
                  flexDirection={{ xs: "column", md: "row !important" }}
                  position={{
                    lg: paymentSuccessData?.vorkasse ? "absolute" : "static",
                  }}
                  bottom={0}
                  minWidth={{ lg: "41.5%" }}
                  maxWidth={{
                    lg: paymentSuccessData?.vorkasse ? "41.5%" : "auto",
                  }}
                >
                  <Box
                    mr={{ md: 1 }}
                    width="100%"
                    mb={{ xs: 2, md: "0 !important" }}
                  >
                    <Button
                      className="btn-theme btn-submit btn-small"
                      variant="contained"
                      color="primary"
                      onClick={ticketHome}
                    >
                      {t("Zurück zur Startseite")}
                    </Button>
                  </Box>
                  <Box width="100%">
                    <Button
                      className="btn-theme btn-submit btn-small"
                      variant="outlined"
                      color="secondary"
                      onClick={ticketStatus}
                    >
                      {t("Ticketstatus abfragen")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {paymentSuccessData?.vorkasse && (
          <Grid item xs={12} md={5}>
            <Box component="section">
              <Box component="h2" mb={3} className="main-title-theme">
                {t("Zahlungsinformationen")}:
              </Box>
              <Grid item container spacing={2} alignItems="center">
                <Grid item xs={6} sm={5}>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {t("Empfänger")}:
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {t("IBAN")}:
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {t("BIC")}:
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {t("Verwendungszweck")}:
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {t("Betrag")}:
                  </Box>
                </Grid>
                <Grid item xs={6} sm={7}>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {paymentBankInfo.receiver}
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {paymentBankInfo.iban}
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {paymentBankInfo.bic}
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {ticket.isSemesterWorkflow
                      ? t("Semesterticket")
                      : isKlimaTicket
                      ? t("Klimaticket OÖ")
                      : t("Jahresticket")}{" "}
                    #{orderNumber}
                  </Box>
                  <Box
                    component="p"
                    mb={{ xs: 1, sm: 0 }}
                    className="main-text-theme"
                  >
                    {formatCurrency(amount)}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <Button
                      className="btn-theme btn-print btn-small"
                      variant="outlined"
                      color="secondary"
                      onClick={print}
                    >
                      <FontAwesomeIcon icon="print" />
                      {t("Drucken")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
