import { Moment } from 'moment';

export class User {
  sexcode = '';
  salutation? = '';
  firstname = '';
  lastname = '';
  address = '';
  postalCode = '';
  city = '';
  birthDate: null | Moment = null;
  mail = '';
  mobilephonenr = '';
  ooevgNewsletter = false;
  dataTransferAccompanyingResearch = false;
  customerNr = 0;
  phonenr? = '';
  universityId? = '';
  matrikelNr? = '';
  invoiceCompanyName? = '';
  invoiceUid? = '';
  invoiceSexcode? = '';
  invoiceSalutation? = '';
  invoiceFirstname? = '';
  invoiceLastname? = '';
  invoicePostalCode? = '';
  invoiceCity? = '';
  invoiceAddress? = '';
}

export class Invoice {
  invoiceCompanyName? = '';
  invoiceUid? = '';
  invoiceSexcode? = '';
  invoiceSalutation? = '';
  invoiceFirstname? = '';
  invoiceLastname? = '';
  invoicePostalCode? = '';
  invoiceCity? = '';
  invoiceAddress? = '';
}