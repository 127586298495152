import axios from "axios";
import { config } from "../../config";

export class PaymentService {

  public checkouts = async (data: any): Promise<any> => {
    try {
      const { amount, currency, paymentType, orderToken, entityId, ipAddress } = data;
      const response = await axios.get(`${config.apiUrl}payunitycheckout/${entityId}/${amount}/${currency}/${paymentType}/${orderToken}/${ipAddress}`)
      return response.data;
    } catch (error) {
      return error;
    }
  };

  public paymentStatus = async (checkoutID: string, entityId: string): Promise<any> => {
    try {
      const response = await axios.get(`${config.apiUrl}payunitystatus/${checkoutID}/${entityId}`)
      return response.data;
    } catch (error) {
      return "server error";
    }
  };

  public paymentStatusSEPA = async (paymentID: string, orderToken: string): Promise<any> => {
    try {
      const response = await axios.get(`${config.apiUrl}payunitypaymentstatus/${paymentID}/${config.SEPA_entityId}/${orderToken}`)
      return response.data;
    } catch (error) {
      return "server error";
    }
  };
}