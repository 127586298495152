import i18n from "../../i18n";
import { history } from "react-router-guard";
const query: string = history.location.search;
const localeQuery: string = query.split("sprache=")[1];
const locale: string = localeQuery ?  localeQuery.toLowerCase().substr(0,2) : "de";
export class LangService {
  public getCurrentLang = (): string => {
    return i18n.language || locale;
  }

  public setCurrentLang = (lang: string): void => {
    i18n.changeLanguage(lang);
  }
}
